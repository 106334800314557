@import "_color-variables";

#tid-rss-article-container {
    #tid-rss-article-title {
        color: @gcsaa-font-color;
        font-size: 16px;
        font-weight: bold;
        margin: 5px 0 15px 0;
    }

    p {
        margin-bottom: 12px;
    }

    img {
        margin: 0 15px 10px 0;
        float: left;
        max-height: 300px;
        max-width: 100%;
    }
}

// TODO: TEMPORARY? To support small, fixed number of articles.
#gcsaa-feed-scroll-container {
    display: flex;
    flex-direction: column;

    .toro-gcsaa-feed-item-card {
        flex: 1;
        display: flex;
    }
}
