
.tpw-graph-container {
    .tpw-graph-header {
        p-dropdown {
            .ui-widget {
                min-width: 140px;
            }
        }
    }
}

#toro-weather-graph-dlg-container {
    .play-books-legend-container {
        .twg-legend {
            margin: 0 16px;
        }
    }
}
