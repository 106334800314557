
.tlrs-course-selector-container {
    p-dropdown {
        width: 100%;

        .ui-dropdown {
            width: 100%;
        }
    }
}
