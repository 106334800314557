@font-face {
  font-family: 'soil-scout';
  src:  url('fonts/soil-scout.eot?xd1b5n');
  src:  url('fonts/soil-scout.eot?xd1b5n#iefix') format('embedded-opentype'),
    url('fonts/soil-scout.ttf?xd1b5n') format('truetype'),
    url('fonts/soil-scout.woff?xd1b5n') format('woff'),
    url('fonts/soil-scout.svg?xd1b5n#soil-scout') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ss-id-"], [class*=" ss-id-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'soil-scout' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ss-id-soil-scout-alert:before {
  content: "\e900";
}
.ss-id-soil-scout-checkmark:before {
  content: "\e901";
}
.ss-id-soil-scout-device:before {
  content: "\e902";
}
.ss-id-soil-scout-group:before {
  content: "\e903";
}
