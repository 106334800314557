@sa-divider-color: #080D121A;
@sa-header-color: #F5F7F8;
@sa-border-color: #080D121A;

@sa-border-radius: 4px;

@sa-box-shadow: 0 0.1390332281589508px 0.4634441137313843px 0 rgba(0, 0, 0, 0.01), 0 0.32326313853263855px 1.07754385471344px 0 rgba(0, 0, 0, 0.01),
0 0.5805056691169739px 1.9350190162658691px 0 rgba(0, 0, 0, 0.01), 0 0.9634244441986084px 3.2114148139953613px 0 rgba(0, 0, 0, 0.02),
0 1.5872093439102173px 5.2906975746154785px 0 rgba(0, 0, 0, 0.02), 0 2.773981809616089px 9.24660587310791px 0 rgba(0, 0, 0, 0.02), 0 6px 20px 0 rgba(0, 0, 0, 0.03);

// Components
toro-sa-weather-tb {
    flex: 1;
    display: flex;
}

.sa-flex-wrap-container {
    display: flex;
    flex-wrap: wrap;
}

.toro-spatial-adjust-main {
    flex: 1;
    display: flex;
}

// Overlay
.sa-wo {
    .ui-overlaypanel-content {
        background-color: #1B1E21;
    }

    &.ui-overlaypanel:after {
        border-bottom-color: #1B1E21;
    }

    /* Global styles.css or component-specific CSS file */
    //.p-overlaypanel:before, .p-overlaypanel:after {
    //    border-bottom-color: red !important;
    //}

}

#sa-tch-name {
    .sa-breadcrumbs {
        &.ui-breadcrumb {
            border: none;
            background: transparent;
            padding: 0 10px;
            font-size: 12px;
        }

        ul {
            white-space: nowrap;
            overflow-y: hidden;
        }
    }
}

.leaflet-marker-icon {
    &.circle-label {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        color: white;
        pointer-events: none; /* Prevent the label from blocking mouse events */

        .circle-label-content {
            width: fit-content;
            height: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;

            .circle-icons {
                display: flex;
                justify-content: center;

                img {
                    width: 14px;

                    &:first-child {
                        margin-right: 3px;
                    }
                }
            }

            .variance {
                display: flex;
                justify-content: center;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
}

.sa-confirm-push-dlg {
    &.ui-dialog {
        .ui-dialog-titlebar {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        .ui-dialog-footer {
            background-color: black;
            padding: .9rem;
            border: none;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;

            p-footer {
                justify-content: space-between;

                p-button {
                    width: 49%;

                    &:last-of-type {
                        margin-right: 10px;
                    }

                    .ui-button {
                        width: 100%;
                        background: black;
                        color: white;
                        border-color: #4C5459;
                    }

                    &.ui-button-secondary {
                        min-width: 178px;

                        .ui-button {
                            background-color: #ECEFF1;
                            color: #080D12;
                        }
                    }
                }
            }
        }
    }
}
