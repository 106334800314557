@import "_sizing-variables";
@import "_color-variables";

@app-primary-button-background-color: @toro-red;
@app-primary-button-border-color: #b30000;
@app-primary-button-color: white;

@app-secondary-button-background-color: white;
@app-secondary-button-border-color: #ccc;
@app-secondary-button-color: #333;

@prime-ng-std-btn-color: #007ad9;

body {
    .ui-button {
        min-width: @app-button-min-width;

        color: @app-primary-button-color;
        background-color: @app-primary-button-background-color;
        border-color: @app-primary-button-border-color;

        &:enabled:hover {
            background-color: darken(@app-primary-button-background-color, 10%);
            border-color: darken(@app-primary-button-background-color, 10%);
            color: @app-primary-button-color;
        }

        &:enabled:active {
            background-color: darken(@app-primary-button-background-color, 15%);
            border-color: darken(@app-primary-button-background-color, 15%);
            color: @app-primary-button-color;
        }
    }

    .ui-calendar {
        .ui-button {
            min-width: unset;

            color: white;
            background-color: @prime-ng-std-btn-color;
            border-color: @prime-ng-std-btn-color;

            &:enabled:hover {
                background-color: darken(@prime-ng-std-btn-color, 10%);
                border-color: darken(@prime-ng-std-btn-color, 10%);
            }

            &:enabled:active {
                background-color: darken(@prime-ng-std-btn-color, 15%);
                border-color: darken(@prime-ng-std-btn-color, 15%);
            }
        }
    }

    .ui-datepicker-calendar {
        a.ui-state-default {
            border: 1px solid #ccc;
        }
    }

    .ui-inputnumber {
        .ui-button {
            min-width: unset;

            color: white;
            background-color: @prime-ng-std-btn-color;
            border-color: @prime-ng-std-btn-color;

            &:enabled:hover {
                background-color: darken(@prime-ng-std-btn-color, 10%);
                border-color: darken(@prime-ng-std-btn-color, 10%);
            }

            &:enabled:active {
                background-color: darken(@prime-ng-std-btn-color, 15%);
                border-color: darken(@prime-ng-std-btn-color, 15%);
            }
        }
    }
}

.ui-button-secondary {
    .ui-button {
        border: 1px solid @app-secondary-button-border-color;
        background-color: @app-secondary-button-background-color;
        color: @app-secondary-button-color;

        &:enabled:hover {
            background-color: darken(@app-secondary-button-background-color, 10%);
            border-color: darken(@app-secondary-button-background-color, 10%);
            color: @app-secondary-button-color;
        }

        &:enabled:active {
            background-color: darken(@app-secondary-button-background-color, 15%);
            border-color: darken(@app-secondary-button-background-color, 15%);
            color: @app-secondary-button-color;
        }
    }
}

.ui-tooltip {
    .ui-tooltip-text {
        font-size: 12px;
    }
}

body .ui-datepicker table {
    font-size: 11px;
}

body .ui-dialog {
    .ui-dialog-content {
        //border: none;             // Uncomment these two lines if no border beneath the dialog title is desired.
        //padding-top: 0;

        border-bottom: none; // Uncomment this line if a border beneath the dialog title is desired.

        padding-bottom: 0;
    }

    .ui-dialog-titlebar {
        border: none;
        padding: 9px 14px;
        background-color: white;
        color: #666;
        font-size: 18px;
        font-weight: normal;
    }

    .ui-dialog-footer {
        padding-top: 9px;

        // Reverse order of buttons so we can set focus to far right button.
        p-footer {
            flex: none;
            display: flex;
            justify-content: flex-start;
            flex-direction: row-reverse;

            &.rsd-footer-timestamp {
                flex-direction: row;
            }

            // First-of-type is now the far right button.
            p-button:first-of-type {
                button {
                    margin-right: 0;
                }
            }
        }
    }
}

@keyframes ui-progress-spinner-color {
    100%,
    0% {
        stroke: @toro-red; //    #ffa700;
    }
}

// =============================================================================================================================================================
// Table
// =============================================================================================================================================================

.ui-table-scrollable-body {
    overflow-x: hidden;
}

.ui-table-thead {
    th {
        white-space: normal;
    }
}

html[browser-name="Firefox"] {
    .ui-table-flex-scrollable .ui-table-scrollable-body,
    .ui-table-flex-scrollable .ui-table-virtual-scrollable-body {
        flex: 1 1 0;
    }

    .alert-parameters {
        &.ui-table-flex-scrollable .ui-table-scrollable-body,
        &.ui-table-flex-scrollable .ui-table-virtual-scrollable-body {
            flex: 1;
        }
    }
}

// =============================================================================================================================================================
// Notes Widget
// =============================================================================================================================================================

#id-notes-container {
    p-editor {
        position: absolute;
        top: 1px;
        right: 5px;
        bottom: 44px;
        left: 5px;
        display: flex;

        p-header {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .ui-editor-container {
            flex: 1;
            display: flex;
            flex-direction: column;

            .ui-editor-content {
                &.ql-container {
                    flex: 1;
                    position: relative;

                    .ql-editor {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }
                }
            }
        }
    }
}
