
.toro-pogo-zone-tab-card {
    height: 100%;
    width: 100%;
    //display: flex;
    position: relative;
}

.toro-pogo-zone-card {
    width: 100%;
}

.toro-pogo-green-viewer {
    flex: 1;
    display: flex;
}

.tid-ezl-dialog {
    .ui-dialog-content {
        background: white;
    }
}

.pgv-select {
    &.ui-dropdown {
        width: 100%;
    }
}
