@import "_sizing-variables";
@import "_color-variables";

#toro-turf-cloud-labor-stats-widget-content {

    @media screen and (max-width: 520px) {
        toro-date-picker {
            .toro-date-picker-container {
                width: 310px;

                .tdp-date-label {
                    font-size: 12px;
                }
            }
        }

        #tcls-table-container {
            .ui-table-scrollable-wrapper {
                font-size: 12px;
            }
        }
    }

    @media screen and (max-width: 420px) {
        toro-date-picker {
            .toro-date-picker-container {
                width: 275px;

                .tdp-date-label {
                    font-size: 10px;
                }
            }
        }

        #tcls-table-container {
            .ui-table-scrollable-wrapper {
                font-size: 10px;
            }
        }
    }

}
