@dropdown-font-size: 13px;

p-dropdown[ng-reflect-style-class='tid-ezl-dropdown full-width'] {
    width: 100%;

    .tid-ezl-dropdown {
        &.full-width {
            .ui-dropdown {
                width: 100%;
            }
        }
    }
}

.tid-ezl-dropdown {
    &.ui-dropdown {
        width: 167px;
    }

    &.full-width {
        &.ui-dropdown {
            width: 100%;
        }
    }

    &.ui-widget {
        font-size: @dropdown-font-size;

        .ui-dropdown-label {
            font-size: @dropdown-font-size;
        }

        .ui-dropdown-items {
            font-size: @dropdown-font-size;
        }
    }
}

.tid-ez-fixed-container {
    &.ja-jp {
        .tid-ezl-dropdown {
            &.ui-widget {
                .ui-dropdown-label {
                    font-size: 12px;
                }

                .ui-dropdown-items {
                    font-size: 12px;
                }
            }
        }
    }
}

.tid-ezl-dialog {
    .ui-dialog-content {
        background: linear-gradient(180deg, #efefef, transparent);
    }
}

#toro-bt-dt-modal-content-container {
    #ti-ez-modal-content-wrapper {
        width: 1051px;
        height: 484px;
        max-width: 100%;
        max-height: 100%;
        display: flex;
    }
}

#ez-tab-holes-container {
    .tid-ezl-dropdown.ui-widget {
        .ui-dropdown-label {
            font-size: 16px;
        }
    }
}

#ez-tab-placement-container {
    #ez-tab-placement-summary {
        #tid-ezm-placement-panel {
            width: 260px;

            #tid-ez-fmb-placement-container,
            #tid-ez-lr-placement-container {
                padding: 1px 0;
            }

            .tid-ezm-footer {
                padding: 4px 10px;
                font-size: 14px;
            }

            #tid-ezm-lrp-footer {
                margin-top: 1px;
            }
        }
    }
}
