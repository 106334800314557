/* You can add global styles to this file, and also import other style files */

@import "../assets/fonts/font-awesome/css/google-map-support.css";
@import "@mdi/font/css/materialdesignicons.css";

@import "_weather-icon";
@import "_toro-icons";
@import "../assets/fonts/asset-mgmt-icons/style.css";
@import "../assets/fonts/new-toro-icons/style.css";
@import "../assets/fonts/intellidash-2023-icons/style.css";
@import "../assets/fonts/soil-scout-icons/style.css";
@import "_prime-ng-overrides";
@import "_high-charts-overrides";
@import "a-app-foundation";
@import "b-dash-foundation";
@import "c-dash-components";
@import "d-dash-dashboard";
@import "e-dash-turf-guard";
@import "f-dash-my-turf";
@import "g-dash-turf-cloud";
@import "h-dash-weather-graphs";
@import "i-dash-weather-radar";
@import "j-dash-equipment-tracking";
@import "k-dash-green-sight";
@import "l-dash-lynx";
@import "m-playbooks";
@import "n-task-tracker";
@import "o-five9-chat";
@import "p-decision-tree";
@import "r-ez-locator";
@import "s-lynx-cloud";
@import "t-perry-weather";
@import "u-gcsaa";
@import "v-weather-averages";
@import "w-pogo";
@import "x-soil-scout";
@import "y-spectrum";
@import "z-notes";
@import "za-strike-guard";
@import "zb-maya";
@import "../app/spatial-adjust/styles/z-spatial-adjust";

@import "zz-language-overrides";

