
html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;

    font-family: Verdana, Helvetica, Arial, sans-serif;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    pointer-events: none;
}

.tid-flex-col {
    display: flex;
    flex-direction: column;
}
