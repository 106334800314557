
.tlrs-course-selector-container {
    display: flex;
    align-items: center;

    #tlrs-course-label {
        font-size: 14px;
        margin-right: 5px;
    }

    .ui-dropdown:not(.ui-state-disabled) {
        &.ui-state-focus {
            border: 1px solid #a6a6a6;
            box-shadow: none;
        }
    }

    &.fade-on-resize {
        opacity: 0;
        transition: all 0.5s;
    }
}
