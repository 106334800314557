
.twa-container {

    #twa-source {

        p-multiselect {
            flex: 1;
            position: relative;

            .sources-selector {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                border: 1px solid #ccc;
            }
        }
    }

    &.is-mobile {
        #twa-source {
            p-multiselect {
                .sources-selector {
                    font-size: 12px;
                }
            }
        }

        #twa-data {
            display: none;
        }
    }

}

toro-weather-averages-datum-card {
    width: 50%;

    #wadc-container {
        &.is-mobile {
            width: 100%;
        }
    }
}
