
@border-color: #080D121A;

.toro-maya-gauge {
    flex: 1;
    display: flex;
    flex-direction: column;
}

#my-gauge-container {
    flex: 1;
    position: relative;
    flex-basis: 33%;

    #my-gauge-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
    }

    &.linear {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid @border-color;

        #my-gauge-wrapper {
            top: 26px;
            position: unset;
            display: flex;
        }
    }
}

#my-datum-container {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;

    #my-substance {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .my-value {
        width: 100%;
        display: flex;

        div {
            width: 50%;

            &:first-of-type {
                display: flex;
                justify-content: flex-end;
                margin: 0 5px 4px 0;
            }

            &:last-of-type {
                font-weight: bold;
            }
        }

        &.total {
            div:last-of-type {
                color: #F9B115;
            }
        }

        &.planned {
            div:last-of-type {
                color: #77D768;
            }
        }

        &.ytd {
            div:last-of-type {
                color: #36A9E1;
            }
        }
    }

    &.linear {
        flex-direction: row;
        align-items: center;
        width: 100%;
        display: flex;
        padding: 0 10px;
        justify-content: space-between;

        #my-substance {
            margin: 0 6px 2px 0;
        }

        .my-value {
            display: flex;

            &.total {
                width: 37%
            }

            &.planned {
                padding-left: 5px;
                width: 37%
            }

            &.ytd {
                padding-left: 5px;
                width: 26%;
            }

            &:not(:last-of-type) {
                border-right: 1px solid @border-color;
            }

            div {
                width: unset;
                align-items: center;
                font-size: 11px;

                &:first-of-type {
                    display: flex;
                    color: #646E73;
                    margin: 0 4px;
                }

                &:last-of-type {
                    font-weight: bold;
                }
            }

            &.total {
                div:last-of-type {
                    color: #F9B115;
                }
            }

            &.planned {
                div:last-of-type {
                    color: #77D768;
                }
            }

            &.ytd {
                div:last-of-type {
                    color: #36A9E1;
                }
            }
        }
    }
}

p-dropdown[ng-reflect-style-class='tid-my-dropdown'] {
    flex: 1;
    display: flex;

    &:first-of-type {
        margin-right: 8px;
    }
}

.tid-my-dropdown {
    flex: 1;

    &.ui-dropdown {
        min-width: unset;
    }
}
