@import "_sizing-variables";
@import "_color-variables";

.toro-turf-guard-chart {
    flex: 1;
    display: flex;
    overflow: hidden;
}

.turf-guard-chart-container {
    flex: 1;
    overflow: hidden;
    border: 1px solid transparent;
}

.toro-turf-guard-widget-content {
    flex: 1;
    display: flex;
    overflow: hidden;

    .toro-widget-content-pane {

        &.toro-size-one {
            flex-direction: column;
            overflow: hidden;

            .turf-guard-gauge-container {
                display: flex;
                flex-direction: column;
                overflow: hidden;
            }

            .turf-guard-non-gauge-content {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                opacity: 0;
                transition: opacity 0.5s;

                .turf-guard-static-data {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                &.fade-in-chart {
                    opacity: 1;
                }

                .turf-guard-course-avg-value {
                    font-size: 35px;

                    .turf-guard-units {
                        margin-left: 3px;
                        font-size: 20px;
                    }
                }

                .turf-guard-course-avg-label {
                    font-size: 20px;
                }
            }
        }

        &.toro-size-two {
            position: relative;
            overflow: hidden;

        }
    }
}

#toro-turf-guard-sensors-dlg-container,
#tg-zones-selector-dlg-container {

    .sensor-alert {
        color: @turf-guard-alert-color;
        font-weight: bold;
    }

    // PrimeNg

    .ui-listbox {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .ui-listbox-list-wrapper {
        height: 93%;
    }

    .ui-table {
        font-size: 12px;

        @media screen and (max-width: @tg-sensor-dlg-mobile-breakpoint) {
            font-size: 8px;
        }
    }

    @media screen and (max-width: @tg-sensor-dlg-mobile-breakpoint) {
        // HighCharts
        .tg-sensor-dlg-axis-label {
            font-size: 8px;
        }
    }

}

@media screen and (max-width: @tg-sensor-dlg-mobile-breakpoint) {
    // PrimeNg
    body .ui-button {
        font-size: 12px;
    }
}

//
// Mini Mode Widgets

.toro-turf-guard-widget-content  {
    &.mini-mode-widget-container {

        .toro-mini-mode-content {

            #tg-mm-left-panel {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                overflow: hidden;

                #tg-mm-sensor-info {
                    fa-icon {
                        margin-right: 4px;
                    }

                    .sensor-count {
                        font-weight: bold;
                        margin-right: 3px;
                    }

                    .sensor-text {
                        font-weight: normal;
                    }
                }
            }

            #tg-mm-right-panel {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                max-width: 125px;

                .tg-mm-value-container {
                    display: flex;
                    margin: 3px 0 1px 0;

                    &.align-bottom {
                        align-items: baseline;
                    }
                }
            }
        }
    }
}
