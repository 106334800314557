@import "_sizing-variables";
@import "_color-variables";

@mini-mode-value-text-large: 22px;

.toro-header-alerts-container {
    font-size: 13px;
    color: @turf-guard-alert-color;

    fa-icon {
        margin-right: 5px;
    }

    &.fade-on-resize {
        opacity: 0;
        transition: all 0.5s;
    }
}

.toro-notification-sidebar {
    &.ui-sidebar-right {
        top: 70px;
        bottom: 82px;
        height: unset;
        width: 400px;
        max-width: 99%;
        display: flex;
        padding: 5px;

        .toro-notification-panel {
            flex: 1;
            display: flex;
        }

        @media screen and (max-width: @mobile-header-width-breakpoint) {
            top: 57px;
            bottom: 83px;
        }

        @media screen and (max-width: 674px) {
            top: 57px;
            bottom: 118px;
        }
    }
}

//
// Widgets
//

.toro-dashboard-widget-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: @widget-default-font-color;

    .toro-highcharts-chart {
        opacity: 0;

        &.fade-in-chart {
            transition: all 0.5s;
            opacity: 1;
        }
    }

    .toro-link-button-host {
        position: relative;
    }

    .toro-link-button-container {
        position: absolute;
        top: 5px;
        right: 5px;
        height: @widget-link-button-height-width;
        width: @widget-link-button-height-width;
        border: 1px solid lightgray;
        border-radius: 4px;
        background-color: @widget-link-button-background-color;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        z-index: 1;

        img {
            width: 22px;
        }

        &:hover {
            background-color: darken(@widget-link-button-background-color, 5%);

            &:active {
                background-color: darken(@widget-link-button-background-color, 10%);
            }
        }
    }

    .toro-dashboard-widget-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: @dashboard-widget-header-height;
        padding: @dashboard-widget-header-padding;
        color: @dashboard-widget-header-color;
        font-size: @dashboard-widget-header-font-size;
        border-bottom: 1px solid @dashboard-widget-header-border-color;
        cursor: move;

        &.widget-non-draggable,
        &.is-locked {
            cursor: default;
            user-select: none;
        }

        .toro-widget-header-icon-n-title {
            flex: none;
            display: flex;
            align-items: center;

            .toro-widget-header-icon {
                display: flex;
                margin-right: @dashboard-widget-header-icon-right-margin;
            }

            .toro-widget-header-title {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .toro-widget-header-middle-section {
            &.widget-non-draggable {
                cursor: default;
            }
        }

        .toro-widget-header-toolbar {
            display: flex;

            .toro-widget-header-toolbar-menu {
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            .toro-widget-toolbar-button {
                padding: @dashboard-widget-header-toolbar-button-padding;
                color: lighten(@dashboard-widget-header-toolbar-button-color, 60%);

                &:hover {
                    color: lighten(@dashboard-widget-header-toolbar-button-color, 40%);
                    cursor: pointer;
                }

                &:active {
                    color: @dashboard-widget-header-toolbar-button-color;
                }
            }
        }
    }

    .toro-dashboard-widget-content {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin-top: 3px;
        padding: @dashboard-widget-content-padding;
        opacity: 0;

        &.fade-in-on-load {
            transition: opacity 1s;
            opacity: 1;
        }

        .toro-widget-link {
            width: 100%;
            display: flex;
            padding-right: 20px;
            align-items: flex-end;
            justify-content: flex-end;
            font-size: 12px;
            z-index: 1;

            a {
                color: #333;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            &.is-disabled {
                cursor: default;
                opacity: 0.5;
            }
        }

        .toro-widget-content-pane {
            flex: 1;
            display: flex;
            transition: opacity 0.5s;
            overflow: hidden;

            &.fade-on-resize {
                opacity: 0;
            }

            .toro-widget-link {
                flex: 1;
            }
        }

        .turf-guard-alerts-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 10px;
            padding-left: 30px;
            color: @turf-guard-alert-color;

            .turf-guard-alerts-link {
                margin-bottom: 3px;
                z-index: 1;

                a {
                    color: @turf-guard-alert-color;
                    font-weight: bold;

                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .toro-dashboard-widget-footer {
        display: flex;
        align-items: center;
        color: @dashboard-widget-footer-color;
        font-size: @dashboard-widget-footer-font-size;
        margin: @dashboard-widget-footer-margin;
        min-height: @dashboard-widget-footer-min-height;

        fa-icon {
            color: @dashboard-widget-alert-banner-background-color;
            margin: 0 5px;
            z-index: 3;
            cursor: pointer;
        }

        &.ko-ko {
            margin: 2px 10px 4px 6px;
        }

        &.ja-jp {
            margin: 1px 10px 3px 6px;
        }
    }

    .toro-widget-data-retrieval-failed-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;
        color: @toro-red;
        padding: 0 10px 30px 10px;

        &.fade-in-on-load {
            transition: opacity 1s;
            opacity: 1;
        }

        fa-icon {
            font-size: 35px;
            margin-bottom: 6px;
        }

        span {
            font-size: 16px;

            &.tdw-no-data-reason {
                margin-top: 6px;
                font-size: 13px;
                color: gray;
                text-align: center;
                display: block;
                padding: 0 8px;

                &.link {
                    z-index: 10;
                    cursor: default;
                    pointer-events: none;

                    a {
                        pointer-events: auto;
                    }
                }
            }
        }
    }

    .toro-widget-no-data-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;
        color: @warning-color;
        font-size: 16px;
        padding: 0 10px 30px 10px;

        &.fade-in-on-load {
            transition: opacity 1s;
            opacity: 1;
        }

        fa-icon {
            font-size: 35px;
            margin-bottom: 6px;
        }
    }

}

.widget-alert-tooltip {
    &.ui-tooltip {
        max-width: 300px;
    }

    // tooltip background color.
    .ui-tooltip-text.ui-shadow.ui-corner-all {
        background-color: @dashboard-widget-alert-banner-background-color;
    }
}

// User Preferences Dialog
#toro-user-preferences-container {

    p-tabview {
        flex: 1;
        display: flex;
        height: 100%;

        .ui-tabview {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: .25em 0;

            .ui-tabview-panels {
                flex: 1;
                overflow-x: hidden;

                .ui-tabview-panel {
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }

        @media screen and (max-width: 340px) {
            .ui-table-scrollable-view {
                font-size: 12px;
            }
        }
    }
}

// =======================================================
// Setup Wizard
// =======================================================

.toro-setup-wizard {
    &.ui-dialog {
        border-radius: @wizard-border-radius;

        .ui-dialog-content {
            border-radius: @wizard-border-radius;
            padding: 0;
        }
    }
}


.wiz-cancel-dlg {
    .ui-dialog-content {
        border-radius: 8px;
    }
}

.toro-step-integrations {
    flex: 1;
    display: flex;
}

.wiz-integration-container {
     .wiz-integration-component {
        .wiz-integration-content {
            flex: 1;
            display: flex;
            //margin: 0 -8px;

            .wiz-integration-group {
                border: 1px solid @wizard-border-color;
                border-radius: 10px;

                .step-header {
                    margin-bottom: 5px;
                }

                &#full-content {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    padding: 10px 12px;
                }

                &#left-content {
                    display: flex;
                    flex-direction: column;
                    padding: 10px 12px;
                }

                .toro-step-group {
                    flex: 1;
                    display: flex;
                }

                &.step-group {
                    flex: 1;
                    display: flex;
                    margin: 6px 0;

                    &:last-of-type {
                        margin-bottom: 2px;
                    }

                    .step-info {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &.step-left {
                            flex: none;
                            padding: 0 20px;
                            border-right: 1px solid @wizard-border-color;
                            font-size: 24px;
                            font-weight: bold;
                        }

                        &.step-right {
                            padding: 0 10px;
                            color: @wizard-secondary-text-color;
                        }

                    }
                }
            }

            .toro-step-action {
                flex: 1;
                display: flex;
            }

        }

         &.toro-asset-tracking-integration {
             .toro-step-group {
                 max-height: 120px;
             }

             .toro-step-action {
                 #action-content {
                     img {
                         height: 40px;
                     }
                 }
             }
         }

         &.perry-weather-integration,
         &.soil-scout-integration {
             .toro-step-group {
                 max-height: 120px;
             }

             .toro-step-action {
                 #action-content {
                     img {
                         height: 40px;
                     }
                 }
             }
         }

         &.toro-playbooks-integration,
         &.toro-task-tracker-integration
         {
             .toro-step-action {
                 #action-content {
                     img {
                         height: 40px;
                     }
                 }

                 .task-tracker-api-key {
                     input {
                         max-width: 100%;
                     }
                 }
             }
         }
    }
}

.toro-step-enabling {
    flex: 1;
}

.toro-step-faq {
    flex: 1;
    display: flex;
}

#widget-toggle-container {
    p-inputswitch {
        display: flex;

        //widget-toggle ui-inputswitch ui-widget ui-inputswitch-checked
        .widget-toggle.ui-inputswitch-checked {
            .ui-inputswitch-slider {
                background-color: black;
            }
        }
    }
}

.wiz-faqs.ui-accordion {
    border: 1px solid @wizard-border-color;

    .ui-accordion-header {
        margin-bottom: 0;

        a {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            background-color: white;

            border: none;
            border-bottom: 1px solid @wizard-border-color;
            padding: 16px 14px;

            .ui-accordion-header-text {
                color: #202224;
                font-weight: 400;
            }

            &:hover {
                border: none !important;
                border-bottom: 1px solid @wizard-border-color !important;
            }
        }

        &:not(.ui-state-disabled).ui-state-active {
            a,
            a:hover {
                background-color: white;
                border: none;
                border-bottom: 1px solid @wizard-border-color;
                color: #202224;

                .ui-accordion-header-text {
                    font-weight: bold;
                }

                .ui-accordion-toggle-icon {
                    color: #202224;
                }
            }
        }

        a:focus {
            box-shadow: none;
        }

    }

    .ui-accordion-content {
        background-color: #F5F7F8;
        border: none;
        padding: 10px 14px 25px 32px;
    }
}


// =======================================================
// Mini mode widgets
// =======================================================

.toro-dashboard-widget-content.toro-mini-mode {
    margin-bottom: -8px;
}

.mini-mode-widget-container {
    overflow: hidden;
}

.tmm-multi-content-panel-container {
    flex: 1;
    display: flex;
}

.toro-mini-mode-content-panel {
    flex: 1;
    display: flex;
    border-radius: 5px;
    border-left: 1px solid #eee;
    border-top: 1px solid #eee;
    margin: 5px 3px;
    box-shadow: 4px 2px 7px #eaeaea;
    padding: 7px 7px 9px 7px;
    overflow: hidden;

    &.host-content-panel {
        border: none;
        box-shadow: unset;
        padding: 0;
        margin: unset;
    }

    &:first-of-type {
        margin-left: 4px;
    }

    &:last-of-type {
        margin-right: 4px;
    }

    .tmm-label-small {
        font-size: 11px;
    }

    .toro-mini-mode-content {
        flex: 1;
        display: flex;
        position: relative;
    }

    .tmm-icon-value-row-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .tmm-icon-value-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: -4px;

            &:not(:first-child) {
                margin-top: 2px;
            }

            .tmm-icon-value-item {
                display: flex;
                margin-left: 5px;

                .tmm-iv-datum {
                    display: flex;
                    justify-content: center;
                }

                &.tmm-split-value {
                    > span:first-child {
                        padding-right: 3px;
                        margin-right: 3px;
                    }
                }
            }

            .tmm-iv-value {
                font-size: 12px;
                font-weight: bold;
            }

            .tmm-iv-value-label {
                font-size: 11px;
            }
        }
    }

    .tmm-ul-icon {
        position: absolute;
        top: 0;
        font-size: 20px;
    }

    .tmm-large-value-label {
        font-size: 12px;
        color: @toro-medium-gray;
        text-align: center;
    }

    .tmm-large-value {
        font-size: @tmm-value-lg-font-size;
        font-weight: bold;
    }

    .tmm-medium-value {
        font-size: 20px;
        font-weight: bold;
        display: flex;

        .tmm-medium-value-units {
            font-weight: normal;
            font-size: 16px;
            margin-left: 1px;
        }
    }

    .tmm-small-value {
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: baseline;

        .tmm-small-value-units {
            font-weight: normal;
            font-size: 13px;
            margin-left: 1px;
        }
    }

    .tmm-bot-left-container {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        color: @mini-mode-gray-text;

        .tmm-medium-value {
            margin-bottom: 2px;
        }
    }

    .tmm-bot-right-container {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        width: 100%;

        .tmm-ellipsis-text {
            text-align: right;
        }
    }

    .tmm-centered-column-flex-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .tmm-absolute-container-host {
        position: relative;
        height: 17px;
        display: flex;
        align-items: center;
    }

    .tmm-absolute-container {
        position: absolute;
        right: 0;
        left: 0;
        width: 100%;
        color: @mini-mode-gray-text;
    }

    .tmm-ellipsis-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;

        &.bold {
            font-weight: bold;
        }
    }

    .tmm-split-value {
        > span:first-child {
            border-right: 1px solid @task-tracker-light-gray;
            padding-right: 6px;
            margin-right: 6px;
            margin-bottom: 3px;
        }

        &.tmm-split-value-labels {
            font-size: 11px;
            margin-top: 2px;

            > span:first-child {
                padding-right: 4px;
                margin-right: 4px;
            }
        }
    }

    .tmm-bot-label {
        margin-top: 6px;
    }

    .tmm-extremes-rows-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;

        .tmm-extremes-row {
            display: flex;
            justify-content: space-between;
        }
    }
}

.tmm-carousel-host {
    margin-bottom: 0;

    .tmm-carousel-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &.single-item {
            .toro-dots-container {
                display: none;
            }

            .toro-widget-content-pane {
                height: 114px;
            }
        }
    }
}

// Mini-mode Carousel Container
body {
    .toro-carousel-content {
        &.ui-carousel-content {
            padding: 0;

            .ui-carousel-container {
                margin-left: 5px;
            }
        }

        .ui-button {
            min-width: unset;

            &.ui-carousel-prev,
            &.ui-carousel-next {
                display: none;
            }
        }

        .toro-widget-content-pane {
            height: 99px;

            .toro-mini-mode-content-panel {
                margin: 5px 7px 6px 0;
                padding: 5px;

                &.host-content-panel {
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }

    .toro-dots-container {
        &.ui-carousel-dots-container {
            margin-top: 0;
            margin-bottom: 4px;

            .ui-carousel-dot-item {
                .ui-button {
                    font-size: 10px;
                }
            }
        }

    }
}

.tmm-p-dropdown {

    &.tpw-dropdown {
        min-width: 170px;

        @media screen and (max-width: @mini-mode-mobile-x-small) {
            min-width: 140px;
        }

        @media screen and (max-width: @mini-mode-mobile-xx-small) {
            min-width: 120px;
        }
    }

    &.ui-widget {
        .ui-inputtext,
        .ui-dropdown-item {
            font-size: 13px;

            @media screen and (max-width: @mini-mode-mobile-xx-small) {
                font-size: 12px;
            }
        }
    }
}

// Mini mode modal
#toro-mini-mode-modal-container {
    position: absolute;
    top: 50px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;

    #mini-mode-modal-content-container {
        width: 500px;
        max-width: 100%;
        height: 670px;
        position: relative;

        toro-dashboard-widget-container {
            width: 100%;
        }

    }

}


