@import "_color-variables";

.toro-task-tracker-labor-widget-content,
.toro-task-tracker-measurements-widget-content,
.toro-task-tracker-safeties-widget-content {

    .ttl-so-top-container {
        p-multiselect {
            flex: 1;
            position: relative;

            .department-selector {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            .ui-multiselect {
                border-color: lightgrey;
                border-radius: 5px;
            }
        }
    }

    .ttl-so-bottom-data-container {
        .ttl-progress-bar-container {

            p-progressbar {
                width: 100%;

                .ui-progressbar {
                    height: 8px;

                    &.ui-corner-all {
                        border-radius: unset;
                    }
                }
            }

            &.has-values {
                .ui-progressbar {
                    .ui-progressbar-value {
                        border-right: 1px solid white;
                        border-top-right-radius: unset;
                        border-bottom-right-radius: unset;
                    }
                }
            }

            &.ttl-emp-sum-pb {

                &.has-values {
                    .ui-progressbar {
                        background-color: @task-tracker-orange;

                        .ui-progressbar-value {
                            background-color: @task-tracker-green;
                        }
                    }
                }

            }

            &.ttl-task-assignment-pb {
                &.has-values {
                    .ui-progressbar {
                        background-color: @task-tracker-blue;

                        .ui-progressbar-value {
                            background-color: @task-tracker-green;
                        }
                    }
                }
            }

        }
    }

    .tt-dsi-container {
        .toro-component-shield-container {
            border-radius: 5px;

            &:hover {
                border: 1px solid #ccc;
            }
        }
    }
}

.task-tracker-chart-component {
    flex: 1;
    display: flex;
    position: relative;
    padding: 0 10px;

    .task-tracker-chart-container {
        position: absolute;
        top: 0;
        right: 5px;
        bottom: 0;
        left: 0;
    }

    .tt-no-data-chart-container {
        position: absolute;
        top: 5px;
        right: 0;
        bottom: 5px;
        left: 5px;
        border-radius: 5px;
        background-color: #f5f5f5;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#toro-weather-graph-dlg-container {
    #task-tracker-chart-dlg-container {
        width: 850px;
        height: 500px;
        max-width: 100%;
        max-height: 100%;
        display: flex;
    }

    .ttl-chart-legend {
        justify-content: center;
    }
}

#tt-api-key-dialog-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 5px;

    span,
    p-inputMask {
        margin-bottom: 5px;
    }

    span {
        margin-right: 6px;
    }
}

.tt-no-account-container {
    flex: 1;
    border: 1px solid lightgrey;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
