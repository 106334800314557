@import "_sizing-variables";
@import "_color-variables";

#my-turf-details-table-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .ui-table {
        @media screen and (max-width: @tg-sensor-dlg-mobile-breakpoint) {
            font-size: 10px;
        }
    }

    td {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

}

.tmt-account-info {
    position: absolute;
    top: 4px;
    left: 6px;
    color: #666;
    display: flex;
    flex-direction: column;
    max-width: 285px;
    opacity: 0.8;

    .tmt-account-label {
        font-size: 10px;
        color: #999;
    }

    .tmt-account-name {
        margin-top: 1px;
        color: #888;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.mt-bottom-toolbar-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
}
