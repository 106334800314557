@charset "UTF-8";

@font-face {
  font-family: "toroicons";
  src: url("../assets/fonts/toroicons.woff") format("woff");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "toroicons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "toroicons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-engine-alert:before {
  content: "\62";
}
.icon-maintenance-alert:before {
  content: "\66";
}
.icon-maintenance-due:before {
  content: "\67";
}
.icon-no-maintenance:before {
  content: "\68";
}
.icon-operating-hours:before {
  content: "\69";
}
.icon-parts-needed:before {
  content: "\6b";
}
.icon-parts-on-hand:before {
  content: "\6c";
}
.icon-pto-hours:before {
  content: "\6d";
}
.icon-startup-checklist:before {
  content: "\6e";
}
.icon-tasks-assigned:before {
  content: "\6f";
}
.icon-wireless-alert:before {
  content: "\70";
}
.icon-maintenance:before {
  content: "\71";
}
.icon-500px:before {
  content: "\72";
}
.icon-adjust:before {
  content: "\73";
}
.icon-adn:before {
  content: "\74";
}
.icon-align-center:before {
  content: "\75";
}
.icon-align-justify:before {
  content: "\76";
}
.icon-align-left:before {
  content: "\77";
}
.icon-align-right:before {
  content: "\78";
}
.icon-amazon:before {
  content: "\79";
}
.icon-ambulance:before {
  content: "\7a";
}
.icon-anchor:before {
  content: "\41";
}
.icon-android:before {
  content: "\42";
}
.icon-angellist:before {
  content: "\43";
}
.icon-angle-double-down:before {
  content: "\44";
}
.icon-angle-double-left:before {
  content: "\45";
}
.icon-angle-double-right:before {
  content: "\46";
}
.icon-angle-double-up:before {
  content: "\47";
}
.icon-angle-down:before {
  content: "\48";
}
.icon-angle-left:before {
  content: "\49";
}
.icon-angle-right:before {
  content: "\4a";
}
.icon-angle-up:before {
  content: "\4b";
}
.icon-apple:before {
  content: "\4c";
}
.icon-archive:before {
  content: "\4d";
}
.icon-area-chart:before {
  content: "\4e";
}
.icon-arrow-circle-down:before {
  content: "\4f";
}
.icon-arrow-circle-left:before {
  content: "\50";
}
.icon-arrow-circle-o-down:before {
  content: "\51";
}
.icon-arrow-circle-o-left:before {
  content: "\52";
}
.icon-arrow-circle-o-right:before {
  content: "\53";
}
.icon-arrow-circle-o-up:before {
  content: "\54";
}
.icon-arrow-circle-right:before {
  content: "\55";
}
.icon-arrow-circle-up:before {
  content: "\56";
}
.icon-arrow-down:before {
  content: "\57";
}
.icon-arrow-left:before {
  content: "\58";
}
.icon-arrow-right:before {
  content: "\59";
}
.icon-arrow-up:before {
  content: "\5a";
}
.icon-arrows:before {
  content: "\30";
}
.icon-arrows-alt:before {
  content: "\31";
}
.icon-arrows-h:before {
  content: "\32";
}
.icon-arrows-v:before {
  content: "\33";
}
.icon-asterisk:before {
  content: "\34";
}
.icon-at:before {
  content: "\35";
}
.icon-backward:before {
  content: "\36";
}
.icon-balance-scale:before {
  content: "\37";
}
.icon-ban:before {
  content: "\38";
}
.icon-bar-chart:before {
  content: "\39";
}
.icon-barcode:before {
  content: "\21";
}
.icon-bars:before {
  content: "\22";
}
.icon-battery-empty:before {
  content: "\23";
}
.icon-battery-full:before {
  content: "\24";
}
.icon-battery-half:before {
  content: "\25";
}
.icon-battery-quarter:before {
  content: "\26";
}
.icon-battery-three-quarters:before {
  content: "\27";
}
.icon-bed:before {
  content: "\28";
}
.icon-beer:before {
  content: "\29";
}
.icon-behance:before {
  content: "\2a";
}
.icon-behance-square:before {
  content: "\2b";
}
.icon-bell:before {
  content: "\2c";
}
.icon-bell-o:before {
  content: "\2d";
}
.icon-bell-slash:before {
  content: "\2e";
}
.icon-bell-slash-o:before {
  content: "\2f";
}
.icon-bicycle:before {
  content: "\3a";
}
.icon-binoculars:before {
  content: "\3b";
}
.icon-birthday-cake:before {
  content: "\3c";
}
.icon-bitbucket:before {
  content: "\3d";
}
.icon-bitbucket-square:before {
  content: "\3e";
}
.icon-black-tie:before {
  content: "\3f";
}
.icon-bold:before {
  content: "\40";
}
.icon-bolt:before {
  content: "\5b";
}
.icon-bomb:before {
  content: "\5d";
}
.icon-book:before {
  content: "\5e";
}
.icon-bookmark:before {
  content: "\5f";
}
.icon-bookmark-o:before {
  content: "\60";
}
.icon-briefcase:before {
  content: "\7b";
}
.icon-btc:before {
  content: "\7c";
}
.icon-bug:before {
  content: "\7d";
}
.icon-building:before {
  content: "\7e";
}
.icon-building-o:before {
  content: "\5c";
}
.icon-bullhorn:before {
  content: "\e000";
}
.icon-bullseye:before {
  content: "\e001";
}
.icon-bus:before {
  content: "\e002";
}
.icon-buysellads:before {
  content: "\e003";
}
.icon-calculator:before {
  content: "\e004";
}
.icon-calendar:before {
  content: "\e005";
}
.icon-calendar-check-o:before {
  content: "\e006";
}
.icon-calendar-minus-o:before {
  content: "\e007";
}
.icon-calendar-o:before {
  content: "\e008";
}
.icon-calendar-plus-o:before {
  content: "\e009";
}
.icon-calendar-times-o:before {
  content: "\e00a";
}
.icon-camera:before {
  content: "\e00b";
}
.icon-camera-retro:before {
  content: "\e00c";
}
.icon-car:before {
  content: "\e00d";
}
.icon-caret-down:before {
  content: "\e00e";
}
.icon-caret-left:before {
  content: "\e00f";
}
.icon-caret-right:before {
  content: "\e010";
}
.icon-caret-square-o-down:before {
  content: "\e011";
}
.icon-caret-square-o-left:before {
  content: "\e012";
}
.icon-caret-square-o-right:before {
  content: "\e013";
}
.icon-caret-square-o-up:before {
  content: "\e014";
}
.icon-caret-up:before {
  content: "\e015";
}
.icon-cart-arrow-down:before {
  content: "\e016";
}
.icon-cart-plus:before {
  content: "\e017";
}
.icon-cc:before {
  content: "\e018";
}
.icon-cc-amex:before {
  content: "\e019";
}
.icon-cc-diners-club:before {
  content: "\e01a";
}
.icon-cc-discover:before {
  content: "\e01b";
}
.icon-cc-jcb:before {
  content: "\e01c";
}
.icon-cc-mastercard:before {
  content: "\e01d";
}
.icon-cc-paypal:before {
  content: "\e01e";
}
.icon-cc-stripe:before {
  content: "\e01f";
}
.icon-cc-visa:before {
  content: "\e020";
}
.icon-certificate:before {
  content: "\e021";
}
.icon-chain-broken:before {
  content: "\e022";
}
.icon-check:before {
  content: "\e023";
}
.icon-check-circle:before {
  content: "\e024";
}
.icon-check-circle-o:before {
  content: "\e025";
}
.icon-check-square:before {
  content: "\e026";
}
.icon-check-square-o:before {
  content: "\e027";
}
.icon-chevron-circle-down:before {
  content: "\e028";
}
.icon-chevron-circle-left:before {
  content: "\e029";
}
.icon-chevron-circle-right:before {
  content: "\e02a";
}
.icon-chevron-circle-up:before {
  content: "\e02b";
}
.icon-chevron-down:before {
  content: "\e02c";
}
.icon-chevron-left:before {
  content: "\e02d";
}
.icon-chevron-right:before {
  content: "\e02e";
}
.icon-chevron-up:before {
  content: "\e02f";
}
.icon-child:before {
  content: "\e030";
}
.icon-chrome:before {
  content: "\e031";
}
.icon-circle:before {
  content: "\e032";
}
.icon-circle-o:before {
  content: "\e033";
}
.icon-circle-o-notch:before {
  content: "\e034";
}
.icon-circle-thin:before {
  content: "\e035";
}
.icon-clipboard:before {
  content: "\e036";
}
.icon-clock-o:before {
  content: "\e037";
}
.icon-clone:before {
  content: "\e038";
}
.icon-cloud:before {
  content: "\e039";
}
.icon-cloud-download:before {
  content: "\e03a";
}
.icon-cloud-upload:before {
  content: "\e03b";
}
.icon-code:before {
  content: "\e03c";
}
.icon-code-fork:before {
  content: "\e03d";
}
.icon-codepen:before {
  content: "\e03e";
}
.icon-coffee:before {
  content: "\e03f";
}
.icon-cog:before {
  content: "\e040";
}
.icon-cogs:before {
  content: "\e041";
}
.icon-columns:before {
  content: "\e042";
}
.icon-comment:before {
  content: "\e043";
}
.icon-comment-o:before {
  content: "\e044";
}
.icon-commenting:before {
  content: "\e045";
}
.icon-commenting-o:before {
  content: "\e046";
}
.icon-comments:before {
  content: "\e047";
}
.icon-comments-o:before {
  content: "\e048";
}
.icon-compass:before {
  content: "\e049";
}
.icon-compress:before {
  content: "\e04a";
}
.icon-connectdevelop:before {
  content: "\e04b";
}
.icon-contao:before {
  content: "\e04c";
}
.icon-copyright:before {
  content: "\e04d";
}
.icon-creative-commons:before {
  content: "\e04e";
}
.icon-credit-card:before {
  content: "\e04f";
}
.icon-crop:before {
  content: "\e050";
}
.icon-crosshairs:before {
  content: "\e051";
}
.icon-css3:before {
  content: "\e052";
}
.icon-cube:before {
  content: "\e053";
}
.icon-cubes:before {
  content: "\e054";
}
.icon-cutlery:before {
  content: "\e055";
}
.icon-dashcube:before {
  content: "\e056";
}
.icon-database:before {
  content: "\e057";
}
.icon-delicious:before {
  content: "\e058";
}
.icon-desktop:before {
  content: "\e059";
}
.icon-deviantart:before {
  content: "\e05a";
}
.icon-diamond:before {
  content: "\e05b";
}
.icon-digg:before {
  content: "\e05c";
}
.icon-dot-circle-o:before {
  content: "\e05d";
}
.icon-download:before {
  content: "\e05e";
}
.icon-dribbble:before {
  content: "\e05f";
}
.icon-dropbox:before {
  content: "\e060";
}
.icon-drupal:before {
  content: "\e061";
}
.icon-eject:before {
  content: "\e062";
}
.icon-ellipsis-h:before {
  content: "\e063";
}
.icon-ellipsis-v:before {
  content: "\e064";
}
.icon-empire:before {
  content: "\e065";
}
.icon-envelope:before {
  content: "\e066";
}
.icon-envelope-o:before {
  content: "\e067";
}
.icon-envelope-square:before {
  content: "\e068";
}
.icon-eraser:before {
  content: "\e069";
}
.icon-eur:before {
  content: "\e06a";
}
.icon-exchange:before {
  content: "\e06b";
}
.icon-exclamation:before {
  content: "\e06c";
}
.icon-exclamation-circle:before {
  content: "\e06d";
}
.icon-exclamation-triangle:before {
  content: "\e06e";
}
.icon-expand:before {
  content: "\e06f";
}
.icon-expeditedssl:before {
  content: "\e070";
}
.icon-external-link:before {
  content: "\e071";
}
.icon-external-link-square:before {
  content: "\e072";
}
.icon-eye:before {
  content: "\e073";
}
.icon-eye-slash:before {
  content: "\e074";
}
.icon-eyedropper:before {
  content: "\e075";
}
.icon-facebook:before {
  content: "\e076";
}
.icon-facebook-official:before {
  content: "\e077";
}
.icon-facebook-square:before {
  content: "\e078";
}
.icon-fast-backward:before {
  content: "\e079";
}
.icon-fast-forward:before {
  content: "\e07a";
}
.icon-fax:before {
  content: "\e07b";
}
.icon-female:before {
  content: "\e07c";
}
.icon-fighter-jet:before {
  content: "\e07d";
}
.icon-file:before {
  content: "\e07e";
}
.icon-file-archive-o:before {
  content: "\e07f";
}
.icon-file-audio-o:before {
  content: "\e080";
}
.icon-file-code-o:before {
  content: "\e081";
}
.icon-file-excel-o:before {
  content: "\e082";
}
.icon-file-image-o:before {
  content: "\e083";
}
.icon-file-o:before {
  content: "\e084";
}
.icon-file-pdf-o:before {
  content: "\e085";
}
.icon-file-powerpoint-o:before {
  content: "\e086";
}
.icon-file-text:before {
  content: "\e087";
}
.icon-file-text-o:before {
  content: "\e088";
}
.icon-file-video-o:before {
  content: "\e089";
}
.icon-file-word-o:before {
  content: "\e08a";
}
.icon-files-o:before {
  content: "\e08b";
}
.icon-film:before {
  content: "\e08c";
}
.icon-filter:before {
  content: "\e08d";
}
.icon-fire:before {
  content: "\e08e";
}
.icon-fire-extinguisher:before {
  content: "\e08f";
}
.icon-firefox:before {
  content: "\e090";
}
.icon-flag:before {
  content: "\e091";
}
.icon-flag-checkered:before {
  content: "\e092";
}
.icon-flag-o:before {
  content: "\e093";
}
.icon-flask:before {
  content: "\e094";
}
.icon-flickr:before {
  content: "\e095";
}
.icon-floppy-o:before {
  content: "\e096";
}
.icon-folder:before {
  content: "\e097";
}
.icon-folder-o:before {
  content: "\e098";
}
.icon-folder-open:before {
  content: "\e099";
}
.icon-folder-open-o:before {
  content: "\e09a";
}
.icon-font:before {
  content: "\e09b";
}
.icon-fonticons:before {
  content: "\e09c";
}
.icon-forumbee:before {
  content: "\e09d";
}
.icon-forward:before {
  content: "\e09e";
}
.icon-foursquare:before {
  content: "\e09f";
}
.icon-frown-o:before {
  content: "\e0a0";
}
.icon-futbol-o:before {
  content: "\e0a1";
}
.icon-gamepad:before {
  content: "\e0a2";
}
.icon-gavel:before {
  content: "\e0a3";
}
.icon-gbp:before {
  content: "\e0a4";
}
.icon-genderless:before {
  content: "\e0a5";
}
.icon-get-pocket:before {
  content: "\e0a6";
}
.icon-gg:before {
  content: "\e0a7";
}
.icon-gg-circle:before {
  content: "\e0a8";
}
.icon-gift:before {
  content: "\e0a9";
}
.icon-git:before {
  content: "\e0aa";
}
.icon-git-square:before {
  content: "\e0ab";
}
.icon-github:before {
  content: "\e0ac";
}
.icon-github-alt:before {
  content: "\e0ad";
}
.icon-github-square:before {
  content: "\e0ae";
}
.icon-glass:before {
  content: "\e0af";
}
.icon-globe:before {
  content: "\e0b0";
}
.icon-google:before {
  content: "\e0b1";
}
.icon-google-plus:before {
  content: "\e0b2";
}
.icon-google-plus-square:before {
  content: "\e0b3";
}
.icon-google-wallet:before {
  content: "\e0b4";
}
.icon-graduation-cap:before {
  content: "\e0b5";
}
.icon-gratipay:before {
  content: "\e0b6";
}
.icon-h-square:before {
  content: "\e0b7";
}
.icon-hacker-news:before {
  content: "\e0b8";
}
.icon-hand-lizard-o:before {
  content: "\e0b9";
}
.icon-hand-o-down:before {
  content: "\e0ba";
}
.icon-hand-o-left:before {
  content: "\e0bb";
}
.icon-hand-o-right:before {
  content: "\e0bc";
}
.icon-hand-o-up:before {
  content: "\e0bd";
}
.icon-hand-paper-o:before {
  content: "\e0be";
}
.icon-hand-peace-o:before {
  content: "\e0bf";
}
.icon-hand-pointer-o:before {
  content: "\e0c0";
}
.icon-hand-rock-o:before {
  content: "\e0c1";
}
.icon-hand-scissors-o:before {
  content: "\e0c2";
}
.icon-hand-spock-o:before {
  content: "\e0c3";
}
.icon-hdd-o:before {
  content: "\e0c4";
}
.icon-header:before {
  content: "\e0c5";
}
.icon-headphones:before {
  content: "\e0c6";
}
.icon-heart:before {
  content: "\e0c7";
}
.icon-heart-o:before {
  content: "\e0c8";
}
.icon-heartbeat:before {
  content: "\e0c9";
}
.icon-history:before {
  content: "\e0ca";
}
.icon-home:before {
  content: "\e0cb";
}
.icon-hospital-o:before {
  content: "\e0cc";
}
.icon-hourglass:before {
  content: "\e0cd";
}
.icon-hourglass-end:before {
  content: "\e0ce";
}
.icon-hourglass-half:before {
  content: "\e0cf";
}
.icon-hourglass-o:before {
  content: "\e0d0";
}
.icon-hourglass-start:before {
  content: "\e0d1";
}
.icon-houzz:before {
  content: "\e0d2";
}
.icon-html5:before {
  content: "\e0d3";
}
.icon-i-cursor:before {
  content: "\e0d4";
}
.icon-ils:before {
  content: "\e0d5";
}
.icon-inbox:before {
  content: "\e0d6";
}
.icon-indent:before {
  content: "\e0d7";
}
.icon-industry:before {
  content: "\e0d8";
}
.icon-info:before {
  content: "\e0d9";
}
.icon-info-circle:before {
  content: "\e0da";
}
.icon-inr:before {
  content: "\e0db";
}
.icon-instagram:before {
  content: "\e0dc";
}
.icon-internet-explorer:before {
  content: "\e0dd";
}
.icon-ioxhost:before {
  content: "\e0de";
}
.icon-italic:before {
  content: "\e0df";
}
.icon-joomla:before {
  content: "\e0e0";
}
.icon-jpy:before {
  content: "\e0e1";
}
.icon-jsfiddle:before {
  content: "\e0e2";
}
.icon-key:before {
  content: "\e0e3";
}
.icon-keyboard-o:before {
  content: "\e0e4";
}
.icon-krw:before {
  content: "\e0e5";
}
.icon-language:before {
  content: "\e0e6";
}
.icon-laptop:before {
  content: "\e0e7";
}
.icon-lastfm:before {
  content: "\e0e8";
}
.icon-lastfm-square:before {
  content: "\e0e9";
}
.icon-leaf:before {
  content: "\e0ea";
}
.icon-leanpub:before {
  content: "\e0eb";
}
.icon-lemon-o:before {
  content: "\e0ec";
}
.icon-level-down:before {
  content: "\e0ed";
}
.icon-level-up:before {
  content: "\e0ee";
}
.icon-life-ring:before {
  content: "\e0ef";
}
.icon-lightbulb-o:before {
  content: "\e0f0";
}
.icon-line-chart:before {
  content: "\e0f1";
}
.icon-link:before {
  content: "\e0f2";
}
.icon-linkedin:before {
  content: "\e0f3";
}
.icon-linkedin-square:before {
  content: "\e0f4";
}
.icon-linux:before {
  content: "\e0f5";
}
.icon-list:before {
  content: "\e0f6";
}
.icon-list-alt:before {
  content: "\e0f7";
}
.icon-list-ol:before {
  content: "\e0f8";
}
.icon-list-ul:before {
  content: "\e0f9";
}
.icon-location-arrow:before {
  content: "\e0fa";
}
.icon-lock:before {
  content: "\e0fb";
}
.icon-long-arrow-down:before {
  content: "\e0fc";
}
.icon-long-arrow-left:before {
  content: "\e0fd";
}
.icon-long-arrow-right:before {
  content: "\e0fe";
}
.icon-long-arrow-up:before {
  content: "\e0ff";
}
.icon-magic:before {
  content: "\e100";
}
.icon-magnet:before {
  content: "\e101";
}
.icon-male:before {
  content: "\e102";
}
.icon-map:before {
  content: "\e103";
}
.icon-map-marker:before {
  content: "\e104";
}
.icon-map-o:before {
  content: "\e105";
}
.icon-map-pin:before {
  content: "\e106";
}
.icon-map-signs:before {
  content: "\e107";
}
.icon-mars:before {
  content: "\e108";
}
.icon-mars-double:before {
  content: "\e109";
}
.icon-mars-stroke:before {
  content: "\e10a";
}
.icon-mars-stroke-h:before {
  content: "\e10b";
}
.icon-mars-stroke-v:before {
  content: "\e10c";
}
.icon-maxcdn:before {
  content: "\e10d";
}
.icon-meanpath:before {
  content: "\e10e";
}
.icon-medium:before {
  content: "\e10f";
}
.icon-medkit:before {
  content: "\e110";
}
.icon-meh-o:before {
  content: "\e111";
}
.icon-mercury:before {
  content: "\e112";
}
.icon-microphone:before {
  content: "\e113";
}
.icon-microphone-slash:before {
  content: "\e114";
}
.icon-minus:before {
  content: "\e115";
}
.icon-minus-circle:before {
  content: "\e116";
}
.icon-minus-square:before {
  content: "\e117";
}
.icon-minus-square-o:before {
  content: "\e118";
}
.icon-mobile:before {
  content: "\e119";
}
.icon-money:before {
  content: "\e11a";
}
.icon-moon-o:before {
  content: "\e11b";
}
.icon-motorcycle:before {
  content: "\e11c";
}
.icon-mouse-pointer:before {
  content: "\e11d";
}
.icon-music:before {
  content: "\e11e";
}
.icon-neuter:before {
  content: "\e11f";
}
.icon-newspaper-o:before {
  content: "\e120";
}
.icon-object-group:before {
  content: "\e121";
}
.icon-object-ungroup:before {
  content: "\e122";
}
.icon-odnoklassniki:before {
  content: "\e123";
}
.icon-odnoklassniki-square:before {
  content: "\e124";
}
.icon-opencart:before {
  content: "\e125";
}
.icon-openid:before {
  content: "\e126";
}
.icon-opera:before {
  content: "\e127";
}
.icon-optin-monster:before {
  content: "\e128";
}
.icon-outdent:before {
  content: "\e129";
}
.icon-pagelines:before {
  content: "\e12a";
}
.icon-paint-brush:before {
  content: "\e12b";
}
.icon-paper-plane:before {
  content: "\e12c";
}
.icon-paper-plane-o:before {
  content: "\e12d";
}
.icon-paperclip:before {
  content: "\e12e";
}
.icon-paragraph:before {
  content: "\e12f";
}
.icon-pause:before {
  content: "\e130";
}
.icon-paw:before {
  content: "\e131";
}
.icon-paypal:before {
  content: "\e132";
}
.icon-pencil:before {
  content: "\e133";
}
.icon-pencil-square:before {
  content: "\e134";
}
.icon-pencil-square-o:before {
  content: "\e135";
}
.icon-phone:before {
  content: "\e136";
}
.icon-phone-square:before {
  content: "\e137";
}
.icon-picture-o:before {
  content: "\e138";
}
.icon-pie-chart:before {
  content: "\e139";
}
.icon-pied-piper:before {
  content: "\e13a";
}
.icon-pied-piper-alt:before {
  content: "\e13b";
}
.icon-pinterest:before {
  content: "\e13c";
}
.icon-pinterest-p:before {
  content: "\e13d";
}
.icon-pinterest-square:before {
  content: "\e13e";
}
.icon-plane:before {
  content: "\e13f";
}
.icon-play:before {
  content: "\e140";
}
.icon-play-circle:before {
  content: "\e141";
}
.icon-play-circle-o:before {
  content: "\e142";
}
.icon-plug:before {
  content: "\e143";
}
.icon-plus:before {
  content: "\e144";
}
.icon-plus-circle:before {
  content: "\e145";
}
.icon-plus-square:before {
  content: "\e146";
}
.icon-plus-square-o:before {
  content: "\e147";
}
.icon-power-off:before {
  content: "\e148";
}
.icon-print:before {
  content: "\e149";
}
.icon-puzzle-piece:before {
  content: "\e14a";
}
.icon-qq:before {
  content: "\e14b";
}
.icon-qrcode:before {
  content: "\e14c";
}
.icon-question:before {
  content: "\e14d";
}
.icon-question-circle:before {
  content: "\e14e";
}
.icon-quote-left:before {
  content: "\e14f";
}
.icon-quote-right:before {
  content: "\e150";
}
.icon-random:before {
  content: "\e151";
}
.icon-rebel:before {
  content: "\e152";
}
.icon-recycle:before {
  content: "\e153";
}
.icon-reddit:before {
  content: "\e154";
}
.icon-reddit-square:before {
  content: "\e155";
}
.icon-refresh:before {
  content: "\e156";
}
.icon-registered:before {
  content: "\e157";
}
.icon-renren:before {
  content: "\e158";
}
.icon-repeat:before {
  content: "\e159";
}
.icon-reply:before {
  content: "\e15a";
}
.icon-reply-all:before {
  content: "\e15b";
}
.icon-retweet:before {
  content: "\e15c";
}
.icon-road:before {
  content: "\e15d";
}
.icon-rocket:before {
  content: "\e15e";
}
.icon-rss:before {
  content: "\e15f";
}
.icon-rss-square:before {
  content: "\e160";
}
.icon-rub:before {
  content: "\e161";
}
.icon-safari:before {
  content: "\e162";
}
.icon-scissors:before {
  content: "\e163";
}
.icon-search:before {
  content: "\e164";
}
.icon-search-minus:before {
  content: "\e165";
}
.icon-search-plus:before {
  content: "\e166";
}
.icon-sellsy:before {
  content: "\e167";
}
.icon-server:before {
  content: "\e168";
}
.icon-share:before {
  content: "\e169";
}
.icon-share-alt:before {
  content: "\e16a";
}
.icon-share-alt-square:before {
  content: "\e16b";
}
.icon-share-square:before {
  content: "\e16c";
}
.icon-share-square-o:before {
  content: "\e16d";
}
.icon-shield:before {
  content: "\e16e";
}
.icon-ship:before {
  content: "\e16f";
}
.icon-shirtsinbulk:before {
  content: "\e170";
}
.icon-shopping-cart:before {
  content: "\e171";
}
.icon-sign-in:before {
  content: "\e172";
}
.icon-sign-out:before {
  content: "\e173";
}
.icon-signal:before {
  content: "\e174";
}
.icon-simplybuilt:before {
  content: "\e175";
}
.icon-sitemap:before {
  content: "\e176";
}
.icon-skyatlas:before {
  content: "\e177";
}
.icon-skype:before {
  content: "\e178";
}
.icon-slack:before {
  content: "\e179";
}
.icon-sliders:before {
  content: "\e17a";
}
.icon-slideshare:before {
  content: "\e17b";
}
.icon-smile-o:before {
  content: "\e17c";
}
.icon-sort:before {
  content: "\e17d";
}
.icon-sort-alpha-asc:before {
  content: "\e17e";
}
.icon-sort-alpha-desc:before {
  content: "\e17f";
}
.icon-sort-amount-asc:before {
  content: "\e180";
}
.icon-sort-amount-desc:before {
  content: "\e181";
}
.icon-sort-asc:before {
  content: "\e182";
}
.icon-sort-desc:before {
  content: "\e183";
}
.icon-sort-numeric-asc:before {
  content: "\e184";
}
.icon-sort-numeric-desc:before {
  content: "\e185";
}
.icon-soundcloud:before {
  content: "\e186";
}
.icon-space-shuttle:before {
  content: "\e187";
}
.icon-spinner:before {
  content: "\e188";
}
.icon-spoon:before {
  content: "\e189";
}
.icon-spotify:before {
  content: "\e18a";
}
.icon-square:before {
  content: "\e18b";
}
.icon-square-o:before {
  content: "\e18c";
}
.icon-stack-exchange:before {
  content: "\e18d";
}
.icon-stack-overflow:before {
  content: "\e18e";
}
.icon-star:before {
  content: "\e18f";
}
.icon-star-half:before {
  content: "\e190";
}
.icon-star-half-o:before {
  content: "\e191";
}
.icon-star-o:before {
  content: "\e192";
}
.icon-steam:before {
  content: "\e193";
}
.icon-steam-square:before {
  content: "\e194";
}
.icon-step-backward:before {
  content: "\e195";
}
.icon-step-forward:before {
  content: "\e196";
}
.icon-stethoscope:before {
  content: "\e197";
}
.icon-sticky-note:before {
  content: "\e198";
}
.icon-sticky-note-o:before {
  content: "\e199";
}
.icon-stop:before {
  content: "\e19a";
}
.icon-street-view:before {
  content: "\e19b";
}
.icon-strikethrough:before {
  content: "\e19c";
}
.icon-stumbleupon:before {
  content: "\e19d";
}
.icon-stumbleupon-circle:before {
  content: "\e19e";
}
.icon-subscript:before {
  content: "\e19f";
}
.icon-subway:before {
  content: "\e1a0";
}
.icon-suitcase:before {
  content: "\e1a1";
}
.icon-sun-o:before {
  content: "\e1a2";
}
.icon-superscript:before {
  content: "\e1a3";
}
.icon-table:before {
  content: "\e1a4";
}
.icon-tablet:before {
  content: "\e1a5";
}
.icon-tachometer:before {
  content: "\e1a6";
}
.icon-tag:before {
  content: "\e1a7";
}
.icon-tags:before {
  content: "\e1a8";
}
.icon-tasks:before {
  content: "\e1a9";
}
.icon-taxi:before {
  content: "\e1aa";
}
.icon-television:before {
  content: "\e1ab";
}
.icon-tencent-weibo:before {
  content: "\e1ac";
}
.icon-terminal:before {
  content: "\e1ad";
}
.icon-text-height:before {
  content: "\e1ae";
}
.icon-text-width:before {
  content: "\e1af";
}
.icon-th:before {
  content: "\e1b0";
}
.icon-th-large:before {
  content: "\e1b1";
}
.icon-th-list:before {
  content: "\e1b2";
}
.icon-thumb-tack:before {
  content: "\e1b3";
}
.icon-thumbs-down:before {
  content: "\e1b4";
}
.icon-thumbs-o-down:before {
  content: "\e1b5";
}
.icon-thumbs-o-up:before {
  content: "\e1b6";
}
.icon-thumbs-up:before {
  content: "\e1b7";
}
.icon-ticket:before {
  content: "\e1b8";
}
.icon-times:before {
  content: "\e1b9";
}
.icon-times-circle:before {
  content: "\e1ba";
}
.icon-times-circle-o:before {
  content: "\e1bb";
}
.icon-tint:before {
  content: "\e1bc";
}
.icon-toggle-off:before {
  content: "\e1bd";
}
.icon-toggle-on:before {
  content: "\e1be";
}
.icon-trademark:before {
  content: "\e1bf";
}
.icon-train:before {
  content: "\e1c0";
}
.icon-transgender:before {
  content: "\e1c1";
}
.icon-transgender-alt:before {
  content: "\e1c2";
}
.icon-trash:before {
  content: "\e1c3";
}
.icon-trash-o:before {
  content: "\e1c4";
}
.icon-tree:before {
  content: "\e1c5";
}
.icon-trello:before {
  content: "\e1c6";
}
.icon-tripadvisor:before {
  content: "\e1c7";
}
.icon-trophy:before {
  content: "\e1c8";
}
.icon-truck:before {
  content: "\e1c9";
}
.icon-try:before {
  content: "\e1ca";
}
.icon-tty:before {
  content: "\e1cb";
}
.icon-tumblr:before {
  content: "\e1cc";
}
.icon-tumblr-square:before {
  content: "\e1cd";
}
.icon-twitch:before {
  content: "\e1ce";
}
.icon-twitter:before {
  content: "\e1cf";
}
.icon-twitter-square:before {
  content: "\e1d0";
}
.icon-umbrella:before {
  content: "\e1d1";
}
.icon-underline:before {
  content: "\e1d2";
}
.icon-undo:before {
  content: "\e1d3";
}
.icon-university:before {
  content: "\e1d4";
}
.icon-unlock:before {
  content: "\e1d5";
}
.icon-unlock-alt:before {
  content: "\e1d6";
}
.icon-upload:before {
  content: "\e1d7";
}
.icon-usd:before {
  content: "\e1d8";
}
.icon-user:before {
  content: "\e1d9";
}
.icon-user-md:before {
  content: "\e1da";
}
.icon-user-plus:before {
  content: "\e1db";
}
.icon-user-secret:before {
  content: "\e1dc";
}
.icon-user-times:before {
  content: "\e1dd";
}
.icon-users:before {
  content: "\e1de";
}
.icon-venus:before {
  content: "\e1df";
}
.icon-venus-double:before {
  content: "\e1e0";
}
.icon-venus-mars:before {
  content: "\e1e1";
}
.icon-viacoin:before {
  content: "\e1e2";
}
.icon-video-camera:before {
  content: "\e1e3";
}
.icon-vimeo:before {
  content: "\e1e4";
}
.icon-vimeo-square:before {
  content: "\e1e5";
}
.icon-vine:before {
  content: "\e1e6";
}
.icon-vk:before {
  content: "\e1e7";
}
.icon-volume-down:before {
  content: "\e1e8";
}
.icon-volume-off:before {
  content: "\e1e9";
}
.icon-volume-up:before {
  content: "\e1ea";
}
.icon-weibo:before {
  content: "\e1eb";
}
.icon-weixin:before {
  content: "\e1ec";
}
.icon-whatsapp:before {
  content: "\e1ed";
}
.icon-wheelchair:before {
  content: "\e1ee";
}
.icon-wifi:before {
  content: "\e1ef";
}
.icon-wikipedia-w:before {
  content: "\e1f0";
}
.icon-windows:before {
  content: "\e1f1";
}
.icon-wordpress:before {
  content: "\e1f2";
}
.icon-wrench:before {
  content: "\e1f3";
}
.icon-xing:before {
  content: "\e1f4";
}
.icon-xing-square:before {
  content: "\e1f5";
}
.icon-y-combinator:before {
  content: "\e1f6";
}
.icon-yahoo:before {
  content: "\e1f7";
}
.icon-yelp:before {
  content: "\e1f8";
}
.icon-youtube:before {
  content: "\e1f9";
}
.icon-youtube-play:before {
  content: "\e1fa";
}
.icon-youtube-square:before {
  content: "\e1fb";
}
.icon-irrigation:before {
  content: "\63";
}
.icon-attachment:before {
  content: "\6a";
}
.icon-equipment:before {
  content: "\61";
}
.icon-other:before {
  content: "\65";
}
.icon-fuel-amount:before {
  content: "\64";
}
.icon-bulk-publish-icon:before {
  content: "\e1fc";
}
.icon-add-to-work-order:before {
  content: "\e1fd";
}
.icon-myturf-pro:before {
  content: "\e1fe";
}
.icon-bolt-exclamation:before {
  content: "\e1ff";
}
.icon-cart-approval:before {
  content: "\e200";
}
.icon-es:before {
  content: "\e201";
}
.icon-merge:before {
  content: "\e202";
}
.icon-cart-with-checkmark:before {
  content: "\e203";
}
.icon-recieve-parts:before {
  content: "\e204";
}
.icon-truck-with-checkmark:before {
  content: "\e205";
}
