@font-face {
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;

  src: url("../assets/fonts/icomoon.eot");
  src: url("../assets/fonts/icomoon.eot") format("embedded-opentype"),
  url("../assets/fonts/icomoon.ttf") format("truetype"),
  url("../assets/fonts/icomoon.woff") format("woff"),
  url("../assets/fonts/icomoon.svg") format("svg");
}

@font-face {
  font-family: "climacons";
  font-style: normal;
  font-weight: normal;

  src: url("../assets/fonts/climacons-webfont.eot");
  src: url("../assets/fonts/climacons-webfont.eot") format("embedded-opentype"),
  url("../assets/fonts/climacons-webfont.ttf") format("truetype"),
  url("../assets/fonts/climacons-webfont.woff") format("woff"),
  url("../assets/fonts/climacons-webfont.svg") format("svg");
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  -moz-osx-font-smoothing: grayscale;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;

  text-transform: none;

  speak: none;
}

[class^="climacon-"],
[class*=" climacon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "climacons" !important;
  -moz-osx-font-smoothing: grayscale;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;

  text-transform: none;

  speak: none;
}

.icon-droplet:before {
  content: "\1f4a7";
}
.icon-cloudy:before {
  content: "\1f326";
}
.icon-lightning:before {
  content: "\1f329";
}
.icon-partlysunny:before {
  content: "\1f325";
}
.icon-rainy:before {
  content: "\1f327";
}
.icon-snowy:before {
  content: "\1f328";
}
.icon-sunny:before {
  content: "\1f324";
}


.climacon-icon-sun:before {
  content: "\e028";
}
.climacon-icon-cloud-sun:before {
  content: "\e001";
}
.climacon-icon-cloud:before {
  content: "\e000";
}
.climacon-icon-showers-sun:before {
  content: "\e007";
}
.climacon-icon-rain:before {
  content: "\e003";
}
.climacon-icon-snow-sun:before {
  content: "\e019";
}
.climacon-icon-snow:before {
  content: "\e018";
}
.climacon-icon-thunder-sun:before {
  content: "\e026";
}
.climacon-icon-thunder:before {
  content: "\e025";
}
.climacon-icon-showers:before {
  content: "\e006";
}
.climacon-icon-drizzle-sun:before {
  content: "\e00D";
}
.climacon-icon-drizzle:before {
  content: "\e00C";
}
.climacon-icon-hail-sun:before {
  content: "\e013";
}
.climacon-icon-hail:before {
  content: "\e012";
}
.climacon-icon-sleet-sun:before {
  content: "\e010";
}
.climacon-icon-sleet:before {
  content: "\e00F";
}

[class^="icon-weather"] {
  background-repeat: no-repeat;
  background-size: 100%;
}
