@font-face {
  font-family: 'Toro-Icons';
  src:  url('fonts/Toro-Icons.eot?nvycr2');
  src:  url('fonts/Toro-Icons.eot?nvycr2#iefix') format('embedded-opentype'),
    url('fonts/Toro-Icons.ttf?nvycr2') format('truetype'),
    url('fonts/Toro-Icons.woff?nvycr2') format('woff'),
    url('fonts/Toro-Icons.svg?nvycr2#Toro-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="asset-mgmt-icon-"], [class*=" asset-mgmt-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Toro-Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.asset-mgmt-icon-Utility_Outline1:before {
  content: "\e901";
}
.asset-mgmt-icon-Sprayers_Outline1:before {
  content: "\e903";
}
.asset-mgmt-icon-SandPro_Outline1:before {
  content: "\e905";
}
.asset-mgmt-icon-Rotary_Outline1:before {
  content: "\e907";
}
.asset-mgmt-icon-Reels_Outline1:before {
  content: "\e909";
}
.asset-mgmt-icon-ProSweep_Outline1:before {
  content: "\e90b";
}
.asset-mgmt-icon-ProPass_Outline1:before {
  content: "\e90d";
}
.asset-mgmt-icon-ProForce_Outline1:before {
  content: "\e90f";
}
.asset-mgmt-icon-Greens_Ride_Outline1:before {
  content: "\e911";
}
.asset-mgmt-icon-Greens_Walk_Outline1:before {
  content: "\e913";
}
.asset-mgmt-icon-OutCross_Outline1:before {
  content: "\e915";
}
.asset-mgmt-icon-ProCore_Outline1:before {
  content: "\e917";
}
.asset-mgmt-icon-Greens_ride:before {
  content: "\e900";
}
.asset-mgmt-icon-Greens_walk:before {
  content: "\e902";
}
.asset-mgmt-icon-OutCross:before {
  content: "\e904";
}
.asset-mgmt-icon-ProCore:before {
  content: "\e906";
}
.asset-mgmt-icon-ProForce:before {
  content: "\e908";
}
.asset-mgmt-icon-ProPass:before {
  content: "\e90a";
}
.asset-mgmt-icon-ProSweep:before {
  content: "\e90c";
}
.asset-mgmt-icon-Reels:before {
  content: "\e90e";
}
.asset-mgmt-icon-Rotary:before {
  content: "\e910";
}
.asset-mgmt-icon-SandPro:before {
  content: "\e912";
}
.asset-mgmt-icon-Sprayers:before {
  content: "\e914";
}
.asset-mgmt-icon-Utility:before {
  content: "\e916";
}
