#tdt-main-panel-content {
    .tdt-mp-row {
        &#tdt-mp-selector-row {
            p-dropdown {
                .ui-dropdown {
                    min-width: 45%;
                    width: 49%;

                    &:first-child {
                        margin-right: 2%;
                    }
                }
            }
        }

        &#tdt-mp-btn-container {
            toro-widget-button {
                .toro-widget-button-container {
                    flex: 1;
                    max-width: unset;
                    height: unset;
                    font-size: 12px;
                }
            }
        }

    }
}

.toro-decision-tree-datum-row {
    flex: 1;
    display: flex;
    position: relative;
}

#tdt-breakdown-panel-content {
    #tdtb-values-container {

        .toro-decision-tree-breakdown-item {
            flex: 1;
            display: flex;
        }

    }
}

