@import "_sizing-variables";

#tracking-link-btn {
    .toro-link-button-container {
        img {
            padding: 2px;
        }
    }
}

.toro-asset-status-icon {
    width: 25px;
    height: 25px;
    margin-bottom: 1px;

    &.circle {
        border-radius: 15px;
        border: 1px solid black;
    }

    &.asset-status {
        &.off {
            width: 38px;
            height: 18px;
            background-color: lightgray;
            padding: 2px;
            text-align: left;

            .off-inner {
                width: 18px;
                height: 100%;
                background-color: white;
                margin: initial;
            }

        }
    }

    fa-icon {
        font-size: 20px;

        &[icon='times'] {
            color: gray;
        }

        &[icon='minus-circle'] {
            color: gray;
        }
    }
}

.etd-table-content-sizing-wrapper {
    width: 900px;
    height: 600px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 375px) {
        width: 300px;
        height: 400px;
    }
}

#toro-dlg-header-container {
    @media screen and (max-width: 400px) {
        flex-wrap: wrap;
    }

    .title {
        margin-right: 5px;
    }
}

.etd-map-content-sizing-wrapper {
    width: calc(80vw);
    height: calc(80vh);
    display: flex;
    flex-direction: column;

    #etd-map-footer {
        padding: 10px;

        #etd-map-footer-slider {
            padding: 10px 0;
        }

        #etd-map-footer-toolbar {
            display: flex;
            align-items: center;

            #etd-map-footer-buttons {
                display: flex;
                align-items: center;

                p-button {
                    margin-right: 10px;
                }
            }

            #etd-map-footer-date {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;

                #etd-tz-label {
                    margin-left: 10px;
                    font-size: 12px;
                }

                @media screen and (max-width: 375px) {
                    font-size: 12px;

                    #etd-tz-label {
                        font-size: 10px;
                    }
                }

            }

            @media screen and (max-width: 750px) {
                flex-direction: column;
                margin-top: 5px;

                #etd-map-footer-date {
                    margin: 10px 0;
                }
            }

        }
    }
}

#equipment-tracking-dlg-table-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .ui-table.ui-widget {

        .etd-status-col {
            width: 12%;

            &.fr-fr,
            &.it-it {
                width: 125px;
            }

            @media screen and (max-width: @mini-mode-breakpoint) {
                width: 80px;
            }

            @media screen and (max-width: 400px) {
                width: 60px;
            }
        }

        @media screen and (max-width: 730px) {
            font-size: 12px;

            fa-icon {
                font-size: 16px;
            }

            .toro-asset-type-icon {
                font-size: 20px;
            }
        }

        @media screen and (max-width: 630px) {
            font-size: 10px;

            fa-icon {
                font-size: 14px;
            }

            .toro-asset-status-icon {
                width: 20px;
                height: 20px;

                &.asset-status {
                    &.off {
                        width: 30px;
                        height: 14px;

                        .off-inner {
                            width: 14px;
                        }
                    }
                }
            }
        }


        @media screen and (max-width: 530px) {
            font-size: 8px;

            fa-icon {
                font-size: 12px;
            }

            .toro-asset-type-icon {
                font-size: 14px;
            }

            .toro-asset-status-label {
                font-size: 8px;
            }

        }

        @media screen and (max-width: 430px) {
            .toro-asset-type-icon {
                font-size: 12px;
            }

            .toro-asset-status-icon {
                width: 16px;
                height: 16px;

                &.asset-status {
                    &.off {
                        width: 26px;
                        height: 14px;

                        .off-inner {
                            width: 12px;
                        }
                    }
                }
            }
        }

    }
}

#equipment-tracking-map-container {
    flex: 1;
    opacity: 0;
    transition: opacity 0.5s;
    position: relative;

    &.fade-in-on-load {
        opacity: 1;
    }

}

// =============================================================================================================================================================
// Equipment Tracking Map Modal
// =============================================================================================================================================================

@icon-diameter: 18px;

.asset-info {
    display: flex;
    flex-direction: row-reverse;

    .asset-info-status {
        margin-right: 10px;
        display: flex;
        align-items: center;

        .toro-asset-status-icon {
            height: @icon-diameter;
            width: @icon-diameter;

            margin-right: 5px;

            &.broken {
                height: 25px;
                width: unset;
            }
        }

        .asset-status.off {
            width: 40px;
            height: 18px;
        }

        .toro-asset-status-label {
            font-size: 12px;
            font-weight: normal;
        }
    }

    .asset-info-text {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 5px;
        padding-bottom: 2px;
        border-bottom: 1px solid #ccc;
    }
}
