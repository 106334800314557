.five9-frame {
    right: 180px !important;

    #five9-maximize-button {
        top: -132px;
        right: -275px;
        border-radius: 25px;
        height: 50px;
        padding: 25px;
        opacity: 0.5;
        display: none;

        &.show {
            display: inline-block;
        }

        &:hover {
            opacity: 1.0;
        }

        @media screen and (max-width: 674px) {
            top: -166px;
        }

        @media screen and (max-width: 350px) {
            display: none;
        }

        @media screen and (max-height: 699px) {
            display: none;
        }

        .five9-icon {
            top: 8px;
            left: 8px;
        }

        .five9-text {
            display: none;
        }
    }

    #five9-frame-full[style*='display: block'] {
        right: -159px;
        bottom: 81px;
        height: 0 !important;
        transition: all .5s;

        &.full-size-frame {
            height: 590px !important;
        }

        &.zero-size-frame {
            display: block !important;
            height: 0 !important;
        }

        @media screen and (max-width: 674px) {
            bottom: 117px;
        }

        @media screen and (max-width: 375px) {
            bottom: 98px;
        }

        .five9-header {
            text-align: right;
        }
    }
}

[data-tooltip]:before {
    position : absolute;
    content : attr(data-tooltip);
    opacity : 0;

    background: linear-gradient(#3396C6, #2281B8);
    width: 0;
    transition: all 0.5s;

    top: 0;
    height: 50px;
    border-radius: 25px;
    right: 8px;
    padding-left: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
}

[data-tooltip]:hover:before {
    opacity : 1;
    width: 160px;
}

