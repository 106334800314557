@import "_sizing-variables";
@import "_color-variables";

.toro-form-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    span.toro-form-field-label {
        margin-bottom: 5px;
        font-size: @form-field-label-font-size;
    }
}

.toro-flex-child {
    flex: 1;
    display: flex;
    white-space: nowrap;
}

.toro-relative-parent {
    position: relative;
}

.toro-absolute-child {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.toro-component-shield-host {
    position: relative;
}

.toro-root {
    height: 100%;
    display: flex;
    flex-direction: column;

    #toro-connecting-message {
        padding: 10px;
    }

    .toro-main-app-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        opacity: 0;
        overflow: hidden;

        &.locked-and-loaded {
            opacity: 1;
            transition: opacity 0.5s;
        }

        .toro-main-top-pane {
            flex: none;
            display: flex;
        }

        .toro-main-body {
            flex: 1;
            display: flex;
            position: relative;

            .toro-main-content-container {
                display: flex;
                overflow-x: hidden;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                .toro-main-content-child {
                    flex: 1;
                    display: flex;
                }
            }
        }

        .toro-main-bottom-pane {
            flex: none;
            display: flex;
            transition: height 0.5s;
            height: 118px;  // Required for height transition effect when showing/hiding mobile footer.

            a {
                color: @footer-color;
                text-decoration: none;

                &:hover {
                    color: @toro-red;
                    text-decoration: underline;
                }
            }

            &.hide-mobile-footer {
                height: 0;
            }

            @media screen and (max-width: @mini-mode-mobile-small) {
                height: 98.5px;
            }
        }
    }
}

.id-flex {
    display: flex;
}

.id-selectable {
    cursor: pointer;
}

// PrimeNg Overrides

.ui-toast {
    width: 425px;
    max-width: 90%;
}

.ui-toast-top-right {
    margin-top: @toast-container-top-margin;
}

