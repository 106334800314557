@ss-box-shadow: 0px 0.139px 0.463px 0px rgba(0, 0, 0, 0.01), 0px 0.323px 1.078px 0px rgba(0, 0, 0, 0.01), 0px 0.581px 1.935px 0px rgba(0, 0, 0, 0.01), 0px 0.963px 3.211px 0px rgba(0, 0, 0, 0.02), 0px 1.587px 5.291px 0px rgba(0, 0, 0, 0.02), 0px 2.774px 9.247px 0px rgba(0, 0, 0, 0.02), 0px 6px 20px 0px rgba(0, 0, 0, 0.03);

.toro-soil-scout-gauge {
    flex: 1;
    display: flex;
    flex-direction: column;
}

#soil-scout-title-icon {
    height: 20px;
}

#ss-widget-content {
    flex-direction: column;
    border-radius: 4px;
    margin: 4px;
    box-shadow: @ss-box-shadow
}

#ss-datum-container {
    width: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        height: 26px;
        margin-bottom: 4px;
    }

    #ss-datum-value {
        font-size: 20px;
        font-weight: bold;
    }
}

#ss-gauge-container {
    flex: 1;
    position: relative;

    #ss-gauge-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
    }
}

#ss-gauge-btn-container {
    flex: 1;
    display: flex;
    max-height: 55px;
    //border: 1px solid blue;
    margin: 0 3px;
    padding-bottom: 2px;

    .ss-button {
        border-radius: 4px;
        flex: 1;
        display: flex;

        &#ss-alert-btn {
            margin: 2px 5px 6px 2px;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            box-shadow: @ss-box-shadow;
            min-width: 61%;

            #ss-alert-img {
                display: flex;
                margin-top: 1px;

                img {
                    height: 25px;
                }
            }

            #ss-alert-info {
                color: #33393D;

                div {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    &#ss-sensor-count {
                        margin-top: 3px;
                        color: #aaa;
                    }

                }
            }
        }

        &#ss-details-btn {
            background-color: #f4f4f4;
            margin: 2px 0 5px 2px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                cursor: pointer;
                background-color: darken(#f4f4f4, 5%);

                &:active {
                    background-color: darken(#f4f4f4, 10%);
                }
            }
        }
    }
}

.toro-soil-scout-bar-chart,
.toro-soil-scout-line-chart {
    flex: 1;
    display: flex;
    overflow: hidden;

    .highcharts-fixed {
        display: none;
    }
}

#ss-bar-label {
    display: flex;
    cursor: pointer;

    i {
        width: 20px;
        font-size: 20px;

        &.ss-id-soil-scout-group {
            color: #00a373;
        }

        &.ss-id-soil-scout-device {
            color: #365df5;
        }

        &:nth-of-type(2) {
            display: flex;
            justify-content: center;
            width: 50px;
            margin-right: 5px;

            &.ss-id-soil-scout-checkmark {
                color: #3ad5a2;
            }

            &.ss-id-soil-scout-alert {
                color: #f9341a;
            }
        }
    }

    #ss-bar-text {
        font-size: 14px;
    }

    &.is-mobile {
        i {
            width: 24px;
            font-size: 16px;

            &:nth-of-type(2) {
                width: 19px;
            }
        }

        #ss-bar-text {
            font-size: 10px;
        }
    }
}

#ss-bar-value-label {
    text-shadow: rgba(0, 0, 0, 0.3) 1px 1px 2px;
}

.ss-tooltip-container {
    display: flex;
    flex-direction: column;
    align-items: center;;

    .ss-tt-series-name {
        font-size: 12px;
    }

    .ss-tt-value {
        font-size: 14px;
        font-weight: bold;
        margin: 3px 0;
    }

    .ss-tt-date {
        font-size: 12px;
    }
}


.soil-scout-content-panel {

    .toro-mini-mode-content-panel {
        flex-direction: column;
        align-items: center;
        padding: 5px 2px 5px 2px;

        .ellipses-text {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
        }

        .ss-mm-sensor-type {
            font-size: 15px;
            font-weight: bold;

            @media screen and (max-width: 521px) {
                font-size: 13px;
            }

            @media screen and (max-width: 350px) {
                font-size: 11px;
            }
        }

        .ss-mm-sub-label {
            font-size: 13px;
            color: #ccc;
            margin: 2px 0 3px 0;
        }

        .ss-mm-avg-value {
            font-size: 16px;
            font-weight: bold;
            margin: 5px 0 10px 0;
            max-width: 100%;
            text-wrap: initial;
            text-align: center;

            &.is-metric {
                font-size: 13px;
            }
        }

        .ss-mm-alerts {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: bold;

            .ss-alert-img {
                height: 18px;
                margin-right: 4px;
            }
        }
    }
}

// Context Menu
.ss-context-menu {
    &.toro-widget-menu {
        width: 200px;
    }
}

#ss-login-dlg-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 5px 5px 10px 5px;

    .field-lbl {
        margin-bottom: 3px;

        &:nth-of-type(2) {
            margin-top: 10px;
        }
    }
}

// Thresholds Dialog
.ss-thresholds-tab-view {

    &.ui-tabview {
        .ui-tabview-panels {
            padding: 15px 10px 15px 5px;

            p-inputNumber,
            .ui-inputnumber {
                width: 100%;

                .ui-inputnumber-input {
                    text-align: center;
                }
            }

        }
    }

}

// Thresholds Component
.toro-soil-scout-thresholds {
    flex: 1;
    display: flex;
}

// Map Widget

//.weather-radar-widget-container,
#soil-scout-map-container {

    p-gmap {
        flex: 1;
    }

    #ssm-toolbar-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: -1000px;      // Push it way off the component so it doesn't briefly display before it's positioned on the map.

        #ssm-button-container {
            display: flex;
            margin-top: 10px;
            margin-left: 10px;

            .ssm-button {
                background-color: @button-background-color;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px 10px;
                font-size: 14px;
                border-radius: @button-border-radius;
                margin-bottom: 1px;

                &:first-child {
                    margin-right: 5px;
                }

                &:hover {
                    cursor: pointer;
                    background-color: darken(@button-background-color, 5%);

                    &:active {
                        background-color: darken(@button-background-color, 10%);
                    }
                }
            }
        }
    }

    #ssm-overlay-container {
        width: 695px;
        max-width: 98%;
    }
}

.asset-info.ssm {
    display: flex;
    flex-direction: column;

    .asset-info-name {
        font-size: 14px;
    }

    .ssm-tooltip-container {
        .ssm-tooltip-group {
            margin: 8px 0;

            .ssm-tt-lbl {
                font-weight: bold;

                .ssm-tt-value {
                    font-weight: normal;
                }
            }

            .mb {
                margin-bottom: 1px;
            }

            .mtb {
                margin: 1px 0;
            }

            &.details-btn {
                display: flex;
                justify-content: flex-end;
                margin-top: 15px;
                cursor: pointer;

                &:hover {
                    color: black;
                }
            }
        }
    }
}

// Map Overlay
.toro-soil-scout-overlay-datum {
    flex: 1;
    display: flex;
    border-radius: 5px;
}
