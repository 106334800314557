
.twg-chart-container {
    flex: 1;
    display: flex;
    overflow: hidden;
}

.twg-legend-container {
    display: flex;
    align-items: center;
    flex: 1;
    max-height: 25px;
    padding: 0 5px;
    margin-left: 10px;

    .twg-legend {
        flex: 1;
        color: #333;
        display: flex;
        align-items: center;

        .twg-legend-label {
            font-size: 12px;
            margin: 0 3px 0 4px;
        }

        .twg-legend-units {
            font-size: 10px;
        }

        &.ko-ko {
            .twg-legend-label {
                font-size: 11px;
            }
        }

        &.ja-jp {
            .twg-legend-label {
                font-size: 11px;
            }
        }
    }

    &.is-modal {
        .twg-legend {
            .twg-legend-label {
                font-size: 14px;
            }

            .twg-legend-units {
                font-size: 12px;
            }
        }
    }
}

.toro-weather-graph-chart {
    flex: 1;
    display: flex;
    overflow: hidden;
}

.weather-graph-chart-container {
    flex: 1;
    overflow: hidden;
    border: 1px solid transparent;
}

.weather-graph-x-axis-label {
    font-size: 10px;
}

.weather-graph-y-axis-label {
    font-size: 10px;
}

.weather-graph-data-tooltip {
    font-size: 12px;
}

#toro-weather-graph-dlg-container {
    .twg-legend-container {
        margin: 25px 0 15px 0;
        justify-content: center;

        .twg-legend {
            flex: none;
            margin: 0 25px;
            display: flex;
            align-items: center;
        }
    }

    @media screen and (min-width: 650px) {
        .weather-graph-y-axis-label {
            font-size: 12px;
        }
        .weather-graph-x-axis-label {
            font-size: 12px;
        }

    }
}

