@font-face {
  font-family: 'New-Toro-Icons';
  src:  url('fonts/New-Toro-Icons.eot?7lx6se');
  src:  url('fonts/New-Toro-Icons.eot?7lx6se#iefix') format('embedded-opentype'),
    url('fonts/New-Toro-Icons.ttf?7lx6se') format('truetype'),
    url('fonts/New-Toro-Icons.woff?7lx6se') format('woff'),
    url('fonts/New-Toro-Icons.svg?7lx6se#New-Toro-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="toro-am-"], [class*=" toro-am-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'New-Toro-Icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.toro-am-Outcross:before {
  content: "\e900";
}
.toro-am-Rotary:before {
  content: "\e901";
}
.toro-am-SandPro:before {
  content: "\e902";
}
.toro-am-Utility:before {
  content: "\e903";
}
.toro-am-ProSweep:before {
  content: "\e904";
}
.toro-am-Reels:before {
  content: "\e905";
}
.toro-am-GreensRide:before {
  content: "\e906";
}
.toro-am-Sprayer:before {
  content: "\e907";
}
.toro-am-ProCare:before {
  content: "\e908";
}
.toro-am-GreensWalk:before {
  content: "\e909";
}
.toro-am-ProForce:before {
  content: "\e90a";
}
.toro-am-ProPass:before {
  content: "\e90b";
}
