@import "_sizing-variables";

// Widget Container
.toro-widget-menu {
    &.de-de {
        min-width: 212px;
    }

    &.es-es {
        min-width: 225px;
    }

    &.fr-fr {
        min-width: 230px;
    }

    &.it-it,
    &.nl-nl {
        min-width: 210px;
    }
}

// myTurf Widgets
.mt-bottom-toolbar-container {
    &.de-de,
    &.fr-fr {
        .toro-widget-button-container {
            max-width: 114px;
        }
    }

    &.it-it {
        .toro-widget-button-container {
            max-width: 123px;
        }
    }

    &.nl-nl {
        .toro-widget-button-container {
            max-width: 138px;
            font-size: 11px;
        }
    }
}

// Task Tracker Widgets
toro-widget-task-tracker-labor {
    .toro-widget-header-title {
        &.es-es,
        &.fr-fr {
            font-size: 14px;
        }

        &.nl-nl {
            font-size: 15px;
        }

        @media screen and (max-width: @mini-mode-breakpoint) {
            &.it-it {
                font-size: 14px;
            }
            &.fr-fr,
            &.es-es {
                font-size: 13px;
            }
        }
    }
}

toro-widget-task-tracker-safety {
    .toro-widget-header-title {
        @media screen and (max-width: @mini-mode-breakpoint) {
            &.de-de,
            &.es-es,
            &.fr-fr,
            &.it-it {
                font-size: 14px;
            }
        }
    }
}

toro-widget-task-tracker-measurements {
    .toro-widget-header-title {
        @media screen and (max-width: @mini-mode-breakpoint) {
            &.fr-fr,
            &.it-it {
                font-size: 14px;
            }
        }
    }
}

// Turf Guard Widgets
.turf-guard-non-gauge-content {
    .toro-widget-link {
        &.de-de,
        &.it-it {
            .toro-widget-button-container {
                max-width: 116px;
            }
        }

        &.fr-fr {
            .toro-widget-button-container {
                max-width: 121px;
            }
        }

        &.nl-nl {
            .toro-widget-button-container {
                font-size: 12px;
            }
        }
    }
}

