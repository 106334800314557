
// Context Menu
.spec-context-menu {
    &.toro-widget-menu {
        width: 200px;
    }
}

#spec-toolbar {
    width: 100%;

    p-dropdown {
        .ui-dropdown {
            min-width: 49%;
            max-width: 49%;

            &:first-child {
                margin-right: 2%;
            }

            .ui-inputtext {
                overflow: hidden;
                width: 150px;
                text-overflow: ellipsis;

            }
        }

        &.is-free-form {
            .ui-dropdown {
                min-width: 100%;
                max-height: 100%;
            }
        }
    }
}

// 1x2 widget
#spec-left-pane {
    #spec-toolbar {
        p-dropdown {
            .ui-dropdown {
                .ui-inputtext {
                    width: 164px;
                }
            }

            &.is-free-form {
                .ui-dropdown {
                    .ui-inputtext {
                        width: 335px;
                    }
                }
            }
        }
    }
}

// Hide the outline on the google map infoWindow close button
#spec-right-pane {
    #map-container {
        p-gmap {
            button[title="Close"] {
                outline: none;
            }
        }
    }
}

// Spectrum Modal
#ti-ez-modal-content-wrapper {
    .toro-spec-grid-card {
        height: 100%;
        width: 100%;
    }

    #spec-modal-left-pane {
        #sec-table-wrapper {
            margin-right: 8px;
        }
    }

    #map-container {
        //width: 100%;
        flex: 1;
        transition: opacity 0.5s;
        opacity: 0;

        &.fade-in {
            opacity: 1;
        }

        p-gmap {
            width: 100%;
        }
    }
}

// Modal
#spec-modal-content-container {

    #spec-modal-left-pane {
        max-width: 40%;

        @media screen and (max-width: 1120px) {
            max-width: 45%;
        }

        @media screen and (max-width: 995px) {
            max-width: 58%;
        }

        @media screen and (max-width: 830px) {
            max-width: unset;
        }

        #spec-toolbar {
            padding-top: 1px;

            p-dropdown {
                .ui-dropdown {
                    overflow-x: hidden;
                    overflow: unset;
                    min-width: 48%;
                    max-width: 48%;

                    .ui-inputtext {
                        overflow: hidden;
                        width: 200px;
                        text-overflow: ellipsis;
                    }
                }

                &.is-free-form {
                    .ui-dropdown {
                        min-width: 100%;
                        max-height: 100%;

                        .ui-inputtext {
                            width: 410px;
                        }
                    }
                }
            }
        }

        #spec-modal-grid-wrapper {
            margin-right: 3px;
        }
    }

    &.mini-modal {
        flex-direction: column;

        #spec-modal-left-pane {
            max-width: unset;
            width: 100%;

            #spec-toolbar {
                padding-top: 1px;

                p-dropdown {
                    .ui-dropdown {
                        .ui-inputtext {
                            overflow: hidden;
                            width: 160px;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }

            #spec-modal-grid-wrapper {
                margin-right: -5px;
            }

            &.free-form {
                #spec-toolbar {
                    p-dropdown {
                        .ui-dropdown {
                            min-width: 100%;
                            max-width: 100%;
                        }
                    }
                }

            }

        }

        #spec-modal-right-pane {
            margin: 5px 0;
        }
    }
}

// Mini-mode
.spec-mini-widget {
    #spec-toolbar {
        padding: 5px 3px;

        p-dropdown {
            .ui-dropdown {
                min-width: unset;
                max-width: unset;
                width: 100%;
            }
        }
    }
}

