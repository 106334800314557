@font-face {
  font-family: 'intellidash-2022-icons';
  src:  url('fonts/intellidash-2022-icons.eot?mj1z3t');
  src:  url('fonts/intellidash-2022-icons.eot?mj1z3t#iefix') format('embedded-opentype'),
    url('fonts/intellidash-2022-icons.ttf?mj1z3t') format('truetype'),
    url('fonts/intellidash-2022-icons.woff?mj1z3t') format('woff'),
    url('fonts/intellidash-2022-icons.svg?mj1z3t#intellidash-2022-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="toro-id-"], [class*=" toro-id-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'intellidash-2022-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.toro-id-ezl-event:before {
  content: "\e914";
}
.toro-id-ezl-manage-green:before {
  content: "\e915";
}
.toro-id-ezl-pinsheet:before {
  content: "\e916";
}
.toro-id-tg-forecast:before {
  content: "\e910";
}
.toro-id-tg-moisture:before {
  content: "\e911";
}
.toro-id-tg-salinity:before {
  content: "\e912";
}
.toro-id-tg-temperature:before {
  content: "\e913";
}
.toro-id-cloud-Rain:before {
  content: "\e903";
}
.toro-id-dew-point:before {
  content: "\e904";
}
.toro-id-employees:before {
  content: "\e905";
}
.toro-id-evapotranspiration:before {
  content: "\e906";
}
.toro-id-flow:before {
  content: "\e907";
}
.toro-id-humidity:before {
  content: "\e908";
}
.toro-id-maintenance:before {
  content: "\e909";
}
.toro-id-orders:before {
  content: "\e90a";
}
.toro-id-ruler:before {
  content: "\e90b";
}
.toro-id-stations:before {
  content: "\e90c";
}
.toro-id-task-assignment:before {
  content: "\e90d";
}
.toro-id-temperature:before {
  content: "\e90e";
}
.toro-id-wind:before {
  content: "\e90f";
}
.toro-id-estimated-rain:before {
  content: "\e900";
}
.toro-id-grass:before {
  content: "\e901";
}
.toro-id-soil-moisture:before {
  content: "\e902";
}
