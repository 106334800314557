
// To enable tooltips for high-chart when displayed in a p-dialog.
.highcharts-tooltip-container {
    z-index: 2000 !important;
}

// Set cursor on xAxis plot bands to alert users that bars are clickable.
.highcharts-plot-band {
    cursor: pointer;
}

// Set cursor on rotated labels on xAxis bars to alert users that bars are clickable.
.highcharts-axis-labels.highcharts-xaxis-labels {
    span {
        cursor: pointer;
    }
}
