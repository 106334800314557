@import "_sizing-variables";
@import "_color-variables";

.toro-main-content-container {
    .toro-welcome {
        flex: 1;
    }

    .toro-red-text {
        color: @toro-red;
    }
}

.toro-date-picker-container {
    .ui-calendar {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        .ui-inputtext {
            width: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
}

.toro-component-shield {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
}

#toro-dlg-header-container {
    display: flex;
    align-items: center;

    .tetl-last-updated {
        padding-top: 5px;
        margin-right: 2px;
        font-size: 12px;

        .tetl-last-updated-time {
            margin: 0 3px;
        }
    }

    @media screen and (max-width: 650px) {
        font-size: 16px;
    }

    @media screen and (max-width: 359px) {
        font-size: 14px;

        .tetl-last-updated {
            font-size: 10px;
        }
    }
}

.toro-account-issue-component {
    flex: 1;
    display: flex;
}

.toro-label-with-info {
    display: flex;

    fa-icon {
        margin-left: 5px;
        cursor: pointer;
    }
}

.rounded-top-square-bottom-panel {
    flex: 1;
    display: flex;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-left: 1px solid #eee;
    border-top: 1px solid #eee;
    margin: 3px 6px 10px 6px;
    box-shadow: 4px 2px 7px #eaeaea;
    //padding: 7px 7px 9px 7px;
    overflow: hidden;
}

.toro-ellipses-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#toro-modal-content-container {
    #toro-modal-content-wrapper {
        width: 1051px;
        height: 484px;
        max-width: 100%;
        max-height: 100%;
        display: flex;
        position: relative;
    }
}

.sa-push-adj {
    &.ui-button {
        background-color: black;
        border-color: black;

        &:enabled {
            &:hover {
                background-color: lighten(black, 30%);
                border-color: black;

                &:active {
                    background-color: lighten(black, 35%);
                }
            }
        }
    }
}
