@button-border-radius: 2px;
@button-background-color: white;

.weather-radar-widget-container {

    p-gmap {
        flex: 1;
    }

    #twr-toolbar-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: -1000px;      // Push it way off the component so it doesn't briefly display before it's positioned on the map.

        #twr-button-container {
            display: flex;
            margin-top: 10px;

            .twr-button {
                background-color: @button-background-color;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px 10px;
                font-size: 14px;
                border-radius: @button-border-radius;
                margin-bottom: 1px;

                &:first-child {
                    margin-right: 5px;
                }

                &:hover {
                    cursor: pointer;
                    background-color: darken(@button-background-color, 5%);

                    &:active {
                        background-color: darken(@button-background-color, 10%);
                    }
                }
            }
        }

        #twr-legend {
            margin-left: 80px;

            @media screen and (max-width: 521px) {
                margin-left: unset;
                position: absolute;
                top: 55px;
                right: 49px;
            }
        }
    }
}
