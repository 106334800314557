@import "_color-variables";
@import "_sizing-variables";

@pw-clear-green: #5DCC77;
@pw-box-shadow: 0 0.1390332281589508px 0.4634441137313843px 0 rgba(0, 0, 0, 0.01), 0 0.32326313853263855px 1.07754385471344px 0 rgba(0, 0, 0, 0.01),
0 0.5805056691169739px 1.9350190162658691px 0 rgba(0, 0, 0, 0.01), 0 0.9634244441986084px 3.2114148139953613px 0 rgba(0, 0, 0, 0.02),
0 1.5872093439102173px 5.2906975746154785px 0 rgba(0, 0, 0, 0.02), 0 2.773981809616089px 9.24660587310791px 0 rgba(0, 0, 0, 0.02), 0 6px 20px 0 rgba(0, 0, 0, 0.03);
@pw-tab-btn-color: #1B2A34;

.toro-widget-toolbar-button {
    #pw-chart-ico {
        font-size: 18px
    }
}

.toro-perry-weather-widget-content {

    // Mini Mode
    &.mini-mode-widget-container {
        .toro-widget-content-pane {

            .toro-mini-mode-content-panel {

                &.right-panel {

                    .toro-pw-data-card {

                        #pw-data-card-container {

                            #pw-data-card-header {
                                font-size: 12px;
                            }

                            #pw-data-card-body {
                                font-size: 14px;
                                text-align: center;
                            }
                        }
                    }
                }
            }

        }
    }

}

// Context Menu
.pw-context-menu {
    &.toro-widget-menu {
        width: 185px;
    }
}

// Chart Modal
#twa-chart {
    flex: 1;
    display: flex;
}

#pw-graphs-container {
    flex: 1;
    display: flex;
    border-radius: @pw-border-radius;
    box-shadow: @pw-box-shadow;
    margin: 2px 5px 5px 5px;

    #pw-graphs-tabs,
    .pw-dual-graph-container {
        flex: 1;
        display: flex;
        flex-direction: column;

        //#pw-tabs {
        //    display: flex;
        //    justify-content: space-between;
        //
        //    .pw-tab-btn {
        //        background-color: #EFEFEF;
        //        color: @pw-tab-btn-color;
        //        padding: 2px 6px;
        //        border-left: 1px solid lightgrey;
        //        border-top: 1px solid lightgrey;
        //        cursor: pointer;
        //
        //        &:first-of-type {
        //            border-top-left-radius: @pw-border-radius;
        //        }
        //
        //        &:last-of-type {
        //            border-right: 1px solid lightgrey;
        //            border-top-right-radius: @pw-border-radius;
        //        }
        //
        //        &.tab-selected {
        //            background-color: #1B2A34;
        //            color: white;
        //        }
        //
        //        &.pw-chart-btn {
        //            color: #A4C4E4;
        //            background-color: lighten(#A4C4E4, 20%);
        //
        //            &.tab-selected {
        //                background-color: #EFEFEF;
        //                color: @pw-tab-btn-color;
        //            }
        //        }
        //    }
        //
        //    #pw-tab-buttons {
        //        display: flex;
        //
        //        .pw-tab-btn {
        //            display: flex;
        //            align-items: center;
        //        }
        //    }
        //
        //
        //    #pw-tab-graph-selectors {
        //        display: flex;
        //
        //        .pw-tab-btn {
        //            border: 1px solid lightgrey;
        //            border-radius: @pw-border-radius;
        //            margin: 0 2px 2px 2px;
        //        }
        //    }
        //
        //}

        //#pw-tabs-chart {
        //    flex: 1;
        //    display: flex;
        //    border-top: 1px solid #EEE;
        //
        //    #twa-chart {
        //        flex: 1;
        //        display: flex;
        //    }
        //}
    }

    //#pw-tabs-chart {
    //    flex: 1;
    //    display: flex;
    //    border-top: 1px solid #EEE;
    //
    //    #twa-chart {
    //        flex: 1;
    //        display: flex;
    //    }
    //}

    #pw-tabs {
        display: flex;
        justify-content: space-between;

        .pw-tab-btn {
            background-color: #EFEFEF;
            color: @pw-tab-btn-color;
            padding: 2px 6px;
            border-left: 1px solid lightgrey;
            border-top: 1px solid lightgrey;
            cursor: pointer;

            &:first-of-type {
                border-top-left-radius: @pw-border-radius;
            }

            &:last-of-type {
                border-right: 1px solid lightgrey;
                border-top-right-radius: @pw-border-radius;
            }

            &.tab-selected {
                background-color: #1B2A34;
                color: white;
            }

            &.pw-chart-btn {
                color: #A4C4E4;
                background-color: lighten(#A4C4E4, 20%);

                &.tab-selected {
                    background-color: #EFEFEF;
                    color: @pw-tab-btn-color;
                }
            }
        }

        #pw-tab-buttons {
            display: flex;

            .pw-tab-btn {
                display: flex;
                align-items: center;
            }
        }


        #pw-tab-graph-selectors {
            display: flex;

            .pw-tab-btn {
                border: 1px solid lightgrey;
                border-radius: @pw-border-radius;
                margin: 0 2px 2px 2px;
            }
        }

    }

    #pw-dual-graphs {
        flex: 1;
        display: flex;

        .pw-dual-graph-container {
            flex: 1;
            display: flex;
            max-width: 50%;
            border: 1px solid lightgrey;

            &:first-of-type {
                margin-right: 10px;
            }
        }
    }
}

#pw-tabs {
    display: flex;
    justify-content: space-between;

    .pw-tab-btn {
        background-color: #EFEFEF;
        color: @pw-tab-btn-color;
        padding: 2px 6px;
        border-left: 1px solid lightgrey;
        border-top: 1px solid lightgrey;
        cursor: pointer;

        &:first-of-type {
            border-top-left-radius: @pw-border-radius;
        }

        &:last-of-type {
            border-right: 1px solid lightgrey;
            border-top-right-radius: @pw-border-radius;
        }

        &.tab-selected {
            background-color: #1B2A34;
            color: white;
        }

        &.pw-chart-btn {
            color: #A4C4E4;
            background-color: lighten(#A4C4E4, 20%);

            &.tab-selected {
                background-color: #EFEFEF;
                color: @pw-tab-btn-color;
            }
        }
    }

    #pw-tab-buttons {
        display: flex;

        .pw-tab-btn {
            display: flex;
            align-items: center;
        }
    }


    #pw-tab-graph-selectors {
        display: flex;

        .pw-tab-btn {
            border: 1px solid lightgrey;
            border-radius: @pw-border-radius;
            margin: 0 2px 2px 2px;
        }
    }

}

#pw-tabs-chart {
    flex: 1;
    display: flex;
    border-top: 1px solid #EEE;

    #twa-chart {
        flex: 1;
        display: flex;
    }
}

#pw-chart-legend-container {
    display: flex;
    justify-content: center;
    margin: 10px 0 5px 0;
}

.toro-perry-weather-tabbed-chart {
    flex: 1;
    display: flex;
}
