
#gsw-date-picker {

    &.isMobile {

        .ui-calendar {
            &.ui-calendar-w-btn {
                .ui-inputtext {
                    display: none;
                }
            }

            .ui-datepicker {

                @media screen and (max-width: 490px) {
                    top: 29px !important;
                    left: -79px !important;
                }

                @media screen and (max-width: 411px) {
                    left: -79px !important;
                }

                @media screen and (max-width: 375px) {
                    left: -114px !important;
                }

                @media screen and (max-width: 360px) {
                    left: -130px !important;
                }

                @media screen and (max-width: 320px) {
                    left: -168px !important;
                }
            }
        }


    }

}
